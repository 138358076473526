import React, { Component } from 'reactn'
import { GlowCircle, PageContentImageCmp } from '../../elements';
import '../GenericPage.scss';
import './BestOilPage.scss';

//FiltersHolder,
export default class BestOilPage extends Component {

    render() {
        //let translate = this.global.translate;

        return (
            <div className="GenericPage BestOilPage">
                <PageContentImageCmp img="/images/banners/banner_best.webp" alt="oil card" backgroundColor="#ffffff" />
                
                <GlowCircle img="white-grapefruit5ml-thumbnail.png"/>
                   
            </div>
        )
    }
}
import React, { Component } from 'reactn';
import './CardPersonHolder.scss';
import { CardPerson } from '../../elements';


export default class CardPersonHolder extends Component {
    /* constructor(props) {
         super(props);
 
     }*/

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

        return (
            <div>
                <div className="CardPersonHolder">
                    <CardPerson img="/images/avatars/person100.webp" name="Poznaj Mnie (Marie)" essential1="Drzewko Herbaciane" essential2="ZenGest" essential3="Motivate" link="https://www.facebook.com/OlejkoweNuty/"/>
                    

                    <CardPerson img="/images/avatars/person2.webp" name="Karolina" essential1="Szałwia" essential2="InTune" essential3="Deep Blue" />
                    <CardPerson img="/images/avatars/person5.webp" name="Sylwia" essential1="Lawenda" essential2="Kadzidłowiec" essential3="On guard" />
                    <CardPerson img="/images/avatars/person4.webp" name="Kasia" essential1="OnGuard" essential2="Kadzidłowiec" essential3="Cheer" />
                    <CardPerson img="/images/avatars/person1.webp" name="Iza" essential1="Lawenda" essential2="Kadzidłowiec" essential3="Vetiver" />
                    <CardPerson img="/images/avatars/person3.webp" name="Justyna" essential1="Whisper" essential2="Vetiver" essential3="Copaiba" />

                </div>
            </div>
        );
    }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './PageContentImageCmp.scss';

export default class PageContentImageCmp extends Component {
    static propTypes = {
        img: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }


    render() {

        //var style = {backgroundColor: this.props.backgroundColor};//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);

        return (
            <div className="PageContentImageCmp" >
                <img src={this.props.img} alt={this.props.alt} className='img'/>
            </div>
        )
    }
}

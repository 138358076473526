import axios from 'axios';
import CoreApi from './core_api';
//const qs = require('qs');

export default class CoreProduct {

    static getProduct = (productCode, callbackFun) => {

        let config =
        {
            params: {
                code: productCode
            }
        }

        axios.get(CoreApi.getProductData, config).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not get product")
        )).catch(function (err) {
            console.log(err.message);
        });;
    }

    static getGaleryImages = (productCode, callbackFun) => {

        let config =
        {
            params: {
                code: productCode
            }
        }

        axios.get(CoreApi.getGaleryImages, config).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not retrive any image from galery")
        )).catch(function (err) {
            console.log(err.message);
        });;

    }

    static getProductListing = (listingType, callbackFun) => {

        let config =
        {
            params: {
                type: listingType
            }
        }

        axios.get(CoreApi.getProductListing, config).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not retrive any image from galery")
        )).catch(function (err) {
            console.log(err.message);
        });;
    }

}
import { setGlobal } from 'reactn'
import axios from 'axios';
import CoreApi from './core_api';
const qs = require('qs');

export default class CoreCart {

    static getCart =  () => {
        axios.get(CoreApi.getCart)
            .then(res => (
                res !== undefined && res.status === 200 ? setGlobal({ cart: res.data }) : console.log("cart data not updated")
            ))
            .catch(function (err) {
                console.log(err.message);
            });
    }

    static getMiniCart = () => {
        axios.get(CoreApi.getMinicartInformation)
            .then(res => (
                res !== undefined && res.status === 200 ? setGlobal({ miniCart: res.data }) : console.log("minicart method not updated")
            ))
            .catch(function (err) {
                console.log(err.message);
            });
    }

    static removeProductFromCart = (line) => {
        axios.post(CoreApi.removeProductFromCart, qs.stringify({ "line": line }))
            .then(res => (
                res !== undefined && res.status === 200 ? CoreCart.updateCart() : console.log("product not removed")
            ))
            .catch(function (err) {
                console.log(err.message);
            });
    }

    static changeProductQuantity = (line, qty) => {
        axios.post(CoreApi.changeProductQuantity, qs.stringify({ "line": line, "qty": qty }))
            .then(res => (
                res !== undefined && res.status === 200 ? CoreCart.updateCart() : console.log("qty not changed")
            ))
            .catch(function (err) {
                console.log(err.message);
            });
    }

    static addToCart = (productID, qty) => {
        axios.post(CoreApi.addToCart, qs.stringify({ "productID": productID, "qty": qty }))
            .then(res => (
                res !== undefined && res.status === 200 ? CoreCart.updateCart() : console.log("product not added to cart")
            ))
            .catch(function (err) {
                console.log(err.message);
            });
    }

    static setDeliveryMethod = (deliveryMethodCode) => {
        if (deliveryMethodCode !== undefined) {
            let json = qs.stringify({ "code": deliveryMethodCode });

            axios.post(CoreApi.setDeliveryMethod, json)
                .then(res => (
                    res !== undefined && res.status === 200 ? CoreCart.updateCart() : console.log("delivery method not changed")
                ))
                .catch(function (err) {
                    console.log(err.message);
                });
        }
    }

    static setPaymentMethod = (paymentMethodCode) => {
        if (paymentMethodCode !== undefined) {
            let json = qs.stringify({ "code": paymentMethodCode });

            axios.post(CoreApi.setPaymentMethod, json)
                .then(res => (
                    res !== undefined && res.status === 200 ? /*CoreCart.updateCart()*/console.log("payment method changed") : console.log("payment method not changed")
                ))
                .catch(function (err) {
                    console.log(err.message);
                });
        };

    }

    static getDeliveryMethods(callback) {
        axios.get(CoreApi.getDeliveryMethods)
            .then(res => {
                res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrive any data")
            }).catch(function (err) {
                console.log(err.message);
            });
    }

    static getPaymentMethods(callback) {
        axios.get(CoreApi.getPaymentMethods)
            .then(res => {
                res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrive any data")
            }).catch(function (err) {
                console.log(err.message);
            });
    }

    static updateCart = () => {
        CoreCart.getCart();
        CoreCart.getMiniCart();
    }


    static timerCart = undefined;
    static animateCart = () => {
        let cart = document.getElementById('minicart_icon');
        cart.classList.toggle("minicart_animate");

        if (CoreCart.timerCart !== undefined) {
            clearTimeout(CoreCart.timerCart);
        }

        CoreCart.timerCart = setTimeout(() => { document.getElementById('minicart_icon').classList.toggle("minicart_animate") }, 1050);
    }

}
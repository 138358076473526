import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ThreeImagesCmp.scss';
import { Link } from "react-router-dom";

export default class ThreeImagesCmp extends Component {
    static propTypes = {
        imgs: PropTypes.array.isRequired,
        links: PropTypes.array.isRequired,
        texts: PropTypes.array.isRequired,
        backgroundColor: PropTypes.string.isRequired
    }

    constructor(props){
        super(props)
        this.mapLinks = this.mapLinks.bind(this);

    }



    render() {

        var style = { backgroundColor: this.props.backgroundColor };//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);

        var images = this.props.imgs.map(this.mapLinks);

        return (
            <div className="ThreeImagesCmp" style={style}>
                {images}
            </div>
        )
    }


    mapLinks(image, index) {

        var position = ['l', 'c', 'r']
        var $target = "_self";

        if (String(this.props.links[index]).startsWith('http')) {
            $target = "_blank";

            return <a key={index} href={this.props.links[index]} className={position[index] + "img"} target={$target} >
                <div className="img_holder"> 
                <img rel="preload" src={image} alt='' />
                {
                    this.props.texts[index].length > 1 ?
                        <div className={"text " + this.props.fontColorClass}>{this.props.texts[index]}</div>
                        :
                        null
                }
                </div>
            </a>

        } else {

            return <Link key={index} to={ this.props.links[index] } className={position[index] + "img"} target={$target} >
                <div className="img_holder"> 
                <img rel="preload" src={image} alt='' />
                {
                    this.props.texts[index].length > 1 ?
                        <div className={"text " + this.props.fontColorClass}>{this.props.texts[index]}</div>
                        :
                        null
                }
                </div>
            </Link>

        }



    }



}
import React, { Component } from 'reactn'
//import { CardRecipesHolder } from '../../containers';
import '../GenericPage.scss';
import './OilsInfoPage.scss';

import { PageContentImageCmp, CardText } from '../../elements'

//FiltersHolder,
export default class OilsInfoPage extends Component {

    



    render() {
        let translate = this.global.translate;

        var text = "<b>Czym są olejki eteryczne?</b>"
        +"<br /><br />"
        +"Naturalne olejki eteryczne to lotne mieszaniny organicznych związków chemicznych (m.in. estrów, eterów, aldehydów, ketonów) o intensywnym zapachu, występujące w różnych częściach roślin, np. w nasionach, korzeniach, kwiatach, owocach, liściach czy korze. Gromadzą się w roślinach głównie w tkance wydzielniczej jako końcowe produkty przemiany materii. Najczęściej występują w postaci oleistej cieczy, o gęstości mniejszej od gęstości wody, dlatego nie są w stanie się w niej rozpuścić. Mogą za to rozpuścić się w alkoholu etylowym, tłuszczach, wosku czy innych olejach ."
        +"Chociaż samo określenie „eteryczny” zazwyczaj kojarzy się z łagodnością i ulotnością, olejki eteryczne wcale nie są delikatne. Przeciwnie – to silnie skoncentrowane substancje, które mają ogromny wpływ na kondycję zdrowotną człowieka – zarówno fizyczną, jak i psychiczną. Ich zapach jest tak naprawdę przyjemnym dodatkiem, ponieważ przede wszystkim wykazują właściwości terapeutyczne – kojące, rozluźniające, redukujące stres, przeciwzapalne czy antyseptyczne. Stosowane umiejętnie i z umiarem mogą przynieść wiele pozytywnych efektów. I odwrotnie – źle użytkowane mogą przyczynić się do wystąpienia podrażnień, oparzeń i innych uszkodzeń ciała."
    
        translate('filter');

        return (
            <div className="GenericPage OilsInfoPage">

                <PageContentImageCmp img="/images/banners/banner_info.webp" alt="oil recipes card" backgroundColor="#ffffff" />
                <CardText text={text} />



            </div>
        )
    }
}

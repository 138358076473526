import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CardText.scss';

export default class CardText extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
    }

    render() {

        return (
            <div className="CardText">
                <div className="card" dangerouslySetInnerHTML={{__html: this.props.text}}>
                    
                </div>
            </div>
        )
    }
}

import React, { Component } from 'reactn';
import './CardRecipesHolder.scss';
import { CardRecipe, FilterList } from '../../elements';
//OilBottle

export default class CardRecipesHolder extends Component {
    static list =[
        {
            name: 'Spray na komary i kleszcze (a...sio)', description: 'Olejek który naturalne odstrasza kleszcze i komary. Do tego pachnie delikatnie nutką cytryny, odświeżającej w gorące dni.',
            oils: [
                { color: 'red', level: 0.42, oil_name: 'Terrashield', oil_drops:8 , oil_units: ''},
                { color: 'yellow', level: 0.31, oil_name: 'Citronella' , oil_drops:6, oil_units: ''},
                { color: 'blue', level: 0.26, oil_name: 'Lawenda' , oil_drops:5, oil_units: ''},
                { color: 'blue', level: 0.70, oil_name: 'Woda' , oil_drops:30, oil_units: 'ml'}
            ]
        }

    ]
    /*
    static list = [
       ,
        {
            name: 'Weekendowy Relax', description: 'aaaaadfadfasdaa',
            oils: [
                { color: 'red', level: 0.6, oil_name: 'olejek 1' },
                { color: 'blue', level: 0.7, oil_name: 'olejek 2' },
                { color: 'yellow', level: 0.3, oil_name: 'olejek 3' },
            ]
        },
        {
            name: 'Wzmocnienie', description: 'aaasdfasd',
            oils: [
                { color: 'red', level: 0.6, oil_name: 'olejek 1' },
                { color: 'blue', level: 0.7, oil_name: 'olejek 2' },
                { color: 'yellow', level: 0.3, oil_name: 'olejek 3' },
                { color: 'green', level: 0.2, oil_name: 'olejek 4' },
                { color: 'teal', level: 0.2, oil_name: 'olejek 5' },
                { color: 'purple', level: 0.2, oil_name: 'olejek 6' },
            ]
        }
    ]*/

    constructor(props) {
        super(props);


        this.state = {
            filter: '',
            recipeList: CardRecipesHolder.list,
            recipeListFiltered: CardRecipesHolder.list

        }

        this.mapRecipe = this.mapRecipe.bind(this);
        this.contains = this.contains.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    mapRecipe($recipe, $index) {
        return <CardRecipe key={$index} name={$recipe.name} description={$recipe.description} oils={$recipe.oils} />
    }

    render() {

        let $recipeDOM = this.state.recipeListFiltered.map(this.mapRecipe);


        return (
            <div className="CardRecipesHolder">
                <FilterList onUpdate={this.handleUpdate} filterNumber={this.state.recipeListFiltered.length} />
                {$recipeDOM}
            </div>
        );
    }


    contains(object, filterText) {
        if (filterText.length === 0) {
            return true;
        }

        let $tmpfilter = filterText.toUpperCase();
        //console.log($tmpfilter);
        return object.name.toUpperCase().indexOf($tmpfilter) > -1
    }

    handleUpdate(filterText) {
        this.setState({ filter: filterText, recipeListFiltered: this.state.recipeList.filter((object) => this.contains(object, filterText)) });

    }

}
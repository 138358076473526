import React, { Component } from 'reactn'
import { CardPersonHolder } from '../../containers';
import '../GenericPage.scss';
import './TeamPage.scss';

import { PageContentImageCmp } from '../../elements/'


export default class TeamPage extends Component {

    render() {
        return (
            <div className="GenericPage TeamPage">
                
                <PageContentImageCmp img="/images/banners/banner_team.jpg" alt="team card" backgroundColor="#ffffff" />

                <CardPersonHolder />

            </div>
        )
    }
}

/* 
{
  ButtonGlass('read more content', 'actionButtonAdd', this.global.translate('cart.qty.add'), null)
}*/

import React, { Component } from 'reactn'
import '../GenericPage.scss';
import './EventsPage.scss';

import { EventEntries, PageContentImageCmp } from '../../elements'

//FiltersHolder,
export default class EventsPage extends Component {

    render() {
        let translate = this.global.translate;

        translate('filter');

        return (
            <div className="GenericPage EventsPage">

                <PageContentImageCmp img="/images/banners/banner_events.jpg" alt="page content img card" backgroundColor="#ffffff" />
                <EventEntries></EventEntries>
            </div>
        )
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "./ProgressBarCmp.scss"


export default class ProgressBarCmp extends Component {
    static propTypes = {
        progress: PropTypes.number.isRequired,
        height: PropTypes.string.isRequired,
        text: PropTypes.string
    }

    render() {
    
        let procent = this.props.progress + "%";
        let text = this.props.text === undefined ?  procent : this.props.text;

        return (

            <div className="ProgressBarCmp" >
                <div className="prog striped light_red " >
                    <div className="progBar" style={{ width: procent }}>
                        <span className="progressBarText" style={{ lineHeight: this.props.height}}> {text} </span>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './BubbleText.scss';

export default class BubbleText extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired
    }


    render() {

        //var style = {backgroundColor: this.props.backgroundColor};//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);

        return (
            <div className="BubbleText">
                <div className="bubble">
                    <div className="txt"> {this.props.text}</div>
                </div>
            </div>
        )
    }
}

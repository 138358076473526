// @flow

import React, { setGlobal, getGlobal } from 'reactn';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SiteHeaderCmp, SiteFooterCmp } from "./components/containers/header/"
import { initLang } from './locales/TranslationManager';
import { initCMS } from './cms/CMS';
import { ButtonToTop } from './components/elements'
import CoreFun from './components/functional/core_fun'
import axios from 'axios'
import './styles/basic.scss';
import CoreApi from './components/functional/core_api';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import addReactNDevTools from 'reactn-devtools';

import {
  HomePage,
  TeamPage,
  RecipesPage,
  OilsPage,
  OilsInfoPage,
  BestOilPage,
  BlogPage,
  EventsPage
} from './components/pages/'
import { CoreCookies, CoreSession } from './components/functional';

const convertToMoney = (value) => {

  let val = 0;
  if (value !== undefined) {
    val = value;
  }

  val = val.toLocaleString(getGlobal().currentLang, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  if (getGlobal().currentLang === 'pl') {
    if (val.length === 7) {
      val = [val.slice(0, 1), " ", val.slice(1)].join('');
    }
  }

  return val;
}

// Set an initial global state directly:
setGlobal({
  cart: undefined,
  session: undefined,
  toMoney: convertToMoney,
  recipesFilters: [],
  cms: undefined

});
//  history: history,

//addReactNDevTools();

initLang();
initCMS();
CoreFun.initArrayUniqueMethod();


export default function App() {
  axios.defaults.headers.common['prefer'] = 'code=200, example=basic';
  axios.defaults.headers.common[CoreApi.sessionID] = CoreCookies.getSessionID(); // for all requests

  CoreSession.getSessionStatus();

  //console.log(CoreCookies.getSessionID());

  function hashLinkScroll() {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  }

  return (

    <BrowserRouter>
      <div className="Altera">
        <SiteHeaderCmp />
        <ButtonToTop />

        <div className="content">
          <Routes
            onUpdate={hashLinkScroll}
          >
            <Route exact path="/" element={<HomePage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/oils" element={<OilsPage />} />
            <Route path="/recipes" element={<RecipesPage />} />
            <Route path="/oils_info" element={<OilsInfoPage />} />
            <Route path="/oils_usage" element={<OilsInfoPage />} />
            <Route path="/best_oil" element={<BestOilPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/events" element={<EventsPage />} />

            <Route path="/cart" element={<HomePage />} />
            <Route index element={<HomePage />} />

          </Routes>

        </div>
        <SiteFooterCmp />
      </div>
    </BrowserRouter>
  );
}
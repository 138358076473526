import { getGlobal, setGlobal } from 'reactn';
import CoreCookies from '../components/functional/core_cookies';

import en from "./en.json";
import pl from "./pl.json";


export const initLang = () => {

    let $curr = 'pl';
    let $alt = 'en';

    let $lang = CoreCookies.getLanguage();

    if ($lang === 'en') {
        $curr = 'en';
        $alt = 'pl';
    }

    setGlobal({
        alternateLang: $alt,
        currentLang: $curr,
        translate: getTranslation,
        changeLang: alterLang
    });


    // if ($lang !== $curr) {
    //     alterLang();
    // }

}


// Render all cards in the global state.
export const getTranslation = (variable) => {
    // Use the hook to get all cards in the global state.
    //   setCards is not used in this example.

    //let defaultLang: 'pl',
    let fallbackLang = en;

    let $currentLang = getGlobal().currentLang;
    //let $defaultLang = getGlobal().defaultLang;
    //let $fallbackLang = getGlobal().fallbackLang;

    //console.log($currentLang);
    // For each card in the global state, render a Card component.

    if ($currentLang === 'pl') {
        if (!pl[variable]) {
            if (!fallbackLang[variable]) {
                console.log("TranslationManager missing translation '" + variable + "'");
                return variable;
            }
            return fallbackLang[variable];
        }

        return pl[variable];
    }

    if ($currentLang === 'en') {
        if (!en[variable]) {
            if (!fallbackLang[variable]) {
                console.log("TranslationManager missing translation '" + variable + "'");
                return variable;
            }
            return fallbackLang[variable];
        }
        return en[variable];
    }

    console.log("TranslationManager missing translation language '" + variable + "'");
    return

};


export const alterLang = () => {
    let global = getGlobal();
    let $curr = global.currentLang;
    let $alt = global.alternateLang;

    CoreCookies.setLanguage($alt);
    setGlobal({ translate: getTranslation, currentLang: $alt, alternateLang: $curr, cms: getGlobal().cms});
    console.log("changing lang to " + getGlobal().currentLang);

}
import { setGlobal } from 'reactn'
import axios from 'axios';
import CoreApi from './core_api';
import CoreCookies from './core_cookies';
//const qs = require('qs');

export default class CoreSession {

    /*
    basketID = -1;
    cartValue = 0;
    cartValue = 0;
    cartDiscount = 0;
    products = [];
    */

    static getSessionStatus = () => {
        axios.get(CoreApi.getSessionStatus).then(res => (
            res !== undefined && res.status === 200 ? CoreSession.callbackHandle (res) : console.log("cannot get session status")
        )).catch(function (err) {
            console.log(err.message);
        });
    }

    static logout = () => {
        axios.get(CoreApi.Logout).then(res => (
            res !== undefined && res.status === 200 ? CoreSession.callbackHandleLogout() : console.log("Cannot logout of session")
        )).catch(function (err) {
            console.log(err.message);
        });
    }

    static callbackHandle(res){
        setGlobal({ session: res.data });
        CoreCookies.setSessionID(res.data.id);
    }

    static callbackHandleLogout(){
        setGlobal({ session: null });
        CoreCookies.releaseSession();
        console.log("Session was logout successfull");
    }
}
import React, { Component } from 'reactn'
import { OilsCardHolder } from '../../containers';
import '../GenericPage.scss';
import './OilsPage.scss';

import { PageContentImageCmp } from '../../elements'

//FiltersHolder,
export default class OilsPage extends Component {

    render() {
        let translate = this.global.translate;

        translate('filter');

        return (
            <div className="GenericPage OilsPage">

                <PageContentImageCmp img="/images/banners/banner_recipes.webp" alt="oil card" backgroundColor="#ffffff" />
                <OilsCardHolder />

            </div>
        )
    }
}

// / <FiltersHolder filters={this.global.recipesFilters}/> 

/**
 * filters: 
 * day night
 * strength
 * presets
 * mood : relaxing, energizing, etc...
 * ingriediences:
 * zastosowanie: wew, zew, dyfuzor
 * Opis
 * ocena up\down
 * 
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CardRecipe.scss';
import { OilBottle } from '..';

export default class CardRecipe extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        oils: PropTypes.array.isRequired
      
        //,backgroundColor: PropTypes.string.isRequired
    }
    // <h4>Idealne do relasacji</h4>
    // <p>Olejek z drzewa herbacianego to ekstrakt z liści drzewa herbacianego (Melaleuca alternifolia) z rodziny mirtowatych, które naturalnie występuje w Australii. Skład olejku stanowią w większości związki fenolowe i terpeny, które wykazują działanie przeciwdrobnoustrojowe. Olejek herbaciany zawiera także monoterpeny, w tym terpinen, cyneol, cymen, diterpeny, seskwiterpeny i wiele innych substancji. Ma on postać rzadkiego oleju o jasnożółtej barwie oraz intensywnym, kamforowym zapachu. Mieszkańcy Australii już od setek lat korzystali z właściwości tej rośliny – używali jej przede wszystkim jako preparat leczniczy.</p>

    constructor(props) {
        super(props);
        
        this.mapOil = this.mapOil.bind(this);
    }
    

    mapOil($oil, $index) {
        return <OilBottle key={$index} color={$oil.color} level={$oil.level} title={$oil.oil_name} drops={$oil.oil_drops} units={$oil.oil_units}/>
    }

    render() {


        let oilsTag = this.props.oils.map(this.mapOil);



        return (
            <div className="CardRecipe">


                <div className="card">
                    <div className='recipe_nav'>
                        <h2>{this.props.name}</h2>
                    </div>

                    <div className="recipe_oils">
                        {oilsTag}
                        
                    </div>

                    
                    <div className="recipe_description">
                        <h2>{this.props.description}</h2>

                    </div>

                    <div className='recipe_footer'>
                    </div>

                </div>
            </div>
        )
    }
}

//  <img src={"/images/products/oils/" + this.props.img} alt={this.props.name} />
                      
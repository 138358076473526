import React, { Component } from 'reactn'
import {HeadBannerCmp, NavBarCmp} from '../../';
import {CookieDisclaimerCmp} from '../../../../elements'

export default class SiteHeaderCmp extends Component {
    
    render() {
          
        let $translate = this.global.translate;
        let $title = $translate("title.main");
        let $subTitle = $translate("title.subtext");
    
        return (
            <div>
                 <HeadBannerCmp url='/' title={ $title } subtitle={ $subTitle } logo='/favicon_olejkowenuty-v2.webp'/>
                 <NavBarCmp/>
                 <CookieDisclaimerCmp /> 
            </div>
        )
    }
}
//
                 
//<HeadBannerCmp url='/' title='Altera' subtitle='Unified Omni-Commerce platform' logo='/images/core/logo_altera.png'/>
                 
import React, { Component } from 'reactn'
import PropTypes from 'prop-types'
import './CardHolderCmp.scss'

export default class CardHolderCmp extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        footer: PropTypes.string,
        isGoBackVisiable: PropTypes.bool,
        customAction: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.global.history.goBack();
    }

    render() {

        let titleGoBack = "";
        let isVisiable = this.props.isGoBackVisiable;
        if (isVisiable !== undefined && isVisiable) {

            let action = this.goBack;

            if(this.props.customAction !== undefined && this.props.customAction !== null){
                action = this.props.customAction;
            }

            titleGoBack = <div className="goBack" onClick={action}> {this.global.translate("generic.btn.goBack")}</div>
        }

        return (
            <div className="CardHolderCmp">
                <div className="holder_title"><div className="text">{this.props.title}</div> {titleGoBack} </div>
                <div className="holder_content">
                    {
                        this.props.children
                    }
                </div>
                <div className="holder_footer">{this.props.footer}</div>
            </div>
        )
    }

}

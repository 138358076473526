import axios from 'axios';
import CoreApi from './core_api';

export default class CoreCustomer {

    static getProfileData = (callbackFun) => {
        axios.get(CoreApi.getProfileData).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not get product")
        )).catch(function (err) {
            console.log(err.message);
        });
    }



    static addRateUs = (value, comment) => {
        if (value !== undefined && comment !== undefined) {
            let json = JSON.stringify({ "value": value, "comment": comment });

            axios.post(CoreApi.addRateUs, json)
                .then(res => (
                    res !== undefined && res.status === 200 ? console.log("thank for sending your opinion") : console.log("Unable to be send opinion")
                ))
                .catch(function (err) {
                    console.log(err.message);
                });
        };
    }





}
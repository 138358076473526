import React, { Component } from 'reactn'
import "./CartCoupons.scss";
import axios from 'axios';
import CoreApi from '../../../functional/core_api';
import { InputFun, ButtonFun } from '../../../elements'
const qs = require('qs');

export default class CartCoupons extends Component {
    constructor(props) {
        super(props);
        this.state = { couponID: '', usedCoupons: [] };
        this.inputHandler = this.inputHandler.bind(this);
        this.loadCoupons = this.loadCoupons.bind(this);
        this.removeCoupon = this.removeCoupon.bind(this);
        this.mapCoupons = this.mapCoupons.bind(this);
    }

    componentDidMount() {
        this.loadCoupons();
    }

    inputHandler = (event) => {
        //1 
        let input = event.target.value;

        // 2
        input = input.replace(/[^0-9a-zA-Z]/g, "");

        var chunk = [];

        // 4
        var joined = input;


        if (input.length > 4 && input.length <= 15) {

            // 3
            let split = 5;

            for (let i = 0, len = input.length; i < len; i += split) {
                //split = (i >= 8 && i <= 16) ? 4 : 8;
                chunk.push(input.substr(i, split));
            }
            joined = chunk.join("-").toUpperCase();

        }

        if (input.length >= 16) {
            // 3
            let split = 4;

            for (let i = 0, len = input.length; i < len; i += split) {
                //split = (i >= 8 && i <= 16) ? 4 : 8;
                chunk.push(input.substr(i, split));
            }
            joined = chunk.join("-").toUpperCase();

        }

        this.setState({ couponID: joined });
    }

    loadCoupons() {
        axios.get(CoreApi.getAciveCoupons).then(res => {
            this.setState({ usedCoupons: res.data });
        }).catch(function (err) {
            console.log(err.message);
        });
    }



    addCoupon(couponCode) {
        axios.post(CoreApi.addActiveCoupon, qs.stringify({ "code": couponCode })).then(
            (res) => (res !== undefined && res.status === 200) ? this.loadCoupons() : null
        ).catch(function (err) {
            console.log(err.message);
        });

        this.setState({ couponID: '' });
    }

    removeCoupon(code) {
        var arr = this.state.usedCoupons.reduce(function (c, n) {
            if (n.code === code) { return c; }
            c.push(n);
            return c;
        }, []);

        this.setState({ usedCoupons: arr });//this.state.usedCoupons.splice(index,1)});

        axios.post(CoreApi.removeCoupon, qs.stringify({ "code": code })).then(res => {
            if (res.status === 200) {
                this.loadCoupons();
            }
        })
    }

    mapCoupons(coupon, index) {

        return <div className="coupon" key={index}>
            <div className="head">{this.global.translate('coupons.usedCoupons.head')}</div>
            <div className="couponHolder">
                <div className="id">{coupon.code}</div>
                <div className="name">{coupon.name}</div>
                <div className="remove"><img src="/images/core/red_bin_48x48.png" alt="remove" className="img" onClick={() => this.removeCoupon(coupon.code)}></img></div>
            </div>
        </div>

    }

    render() {
        let usedCoponds = "";
        let title = "";

        if (this.state.usedCoupons !== undefined && this.state.usedCoupons.length > 0) {
            usedCoponds = this.state.usedCoupons.map(this.mapCoupons);
            title = <div className="couponTitle">{this.global.translate('coupons.usedCouponsTitle')}</div>
        }

        let disabled = this.state.couponID.length > 5 ? false : true;

        return (
            <div className="CartCoupons">
                <div className="title">{this.global.translate('coupons.title')}</div>
                {InputFun(this.state.couponID, "inputCode", this.global.translate('coupons.placeholder'), false, this.inputHandler)}
                {ButtonFun(this.global.translate('coupons.button.add'), "button", undefined, () => { this.addCoupon(this.state.couponID) }, disabled)}
                {title}
                {usedCoponds}
            </div>
        )
    }

}


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './OilBottle.scss';

export default class OilBottle extends Component {
    static propTypes = {
        color: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
        drops: PropTypes.number.isRequired,
        units: PropTypes.string.isRequired,
        title: PropTypes.string
        //,backgroundColor: PropTypes.string.isRequired
    }


    render() {

        //var style = {backgroundColor: this.props.backgroundColor};//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);

        let color = this.props.color;//'red';
        let level = 153 * this.props.level;//1.0;


        let dropsTag = this.props.units === '' ?  ' drops' : '';

        return (
            <div className="OilBottle" >

                <div className={'bottle ' + color} title={this.props.title + ' ' + this.props.drops + ' ' + this.props.units}>
                    <div className='bottle_top'>
                        <div className='bottle_mouth'>
                            <div className='highlight'></div>
                        </div>

                    </div>
                    <div className='bottle_neck'>
                        <div className='highlight'></div>
                    </div>

                    <div className='bottle_main'>
                        <div className='bottle_inner'>
                        <div className={'bottle_drops' + dropsTag }>{this.props.drops + ' ' + this.props.units}</div>
                            <div className='water' style={{height: level }}></div>
                        </div>
                        <div className='highlight'></div>
                    </div>

                </div>

                <div className='bottle_description'>{this.props.title}</div>

            </div>
        )
    }


}

import React, { Component } from 'reactn'
import { Link } from "react-router-dom";
import './CookieDisclaimerCmp.scss';
import CoreCookies from '../../functional/core_cookies';

export default class CookieDisclaimerCmp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDisclaimer: true
        }

        this.acceptCookies = this.acceptCookies.bind(this);
    }

    componentDidMount() {
        let val = CoreCookies.getDisclaimerStatus();
        if (val !== undefined) {
            this.setState({ showDisclaimer: val });
        }
    }

    acceptCookies() {
        CoreCookies.setDisclaimer(false);
        this.setState({ showDisclaimer: false });
    };

    render() {

        let translate = this.global.translate;
        var show = (this.state.showDisclaimer ? { display: 'block' } : { display: 'none' });

        return (
            <div className="CookieDisclaimerCmp" style={show}>
                <div className="holder">
                    <div className="head">{translate('cookies.disclaimer.head')}</div>
                    <div className="inside">{translate('cookies.disclaimer.body')}</div>
                    <span className="space"> </span>
                    <Link to='P&T' target="_blank" className="link">{translate('cookies.disclaimer.link')}</Link>

                    <div className="button" onClick={this.acceptCookies}>{translate('cookies.disclaimer.accept')}</div>
                </div>
            </div>
        )
    }
}
//
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './SwitchDarkMode.scss';

import sun from '../../../assets/images/core/svg/sun.svg';
import moon from '../../../assets/images/core/svg/moon.svg';

export default class SwitchDarkMode extends Component {
    static propTypes = {
        action: PropTypes.func.isRequired,
        checked: PropTypes.bool.isRequired
    }

    render() {
        return (
            <label className='SwitchDarkMode'>
                <input type="checkbox" onChange={this.props.action} defaultChecked={this.props.checked} />
               
                <span className="slider"></span>
                 <img src={sun} alt="React Logo" className='sun' />
                <img src={moon} alt="React Logo" className='moon' />

            </label>
        )
    }

}

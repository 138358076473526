import React, { Component } from 'reactn'
import "./CartDeliveryCmp.scss";
import { CoreCart } from '../../../functional';

export default class CartDeliveryCmp extends Component {
    // Set the state directly. Use props if necessary.
    state = {
        selectedOption: undefined,
        options: []
    };

    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);



        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.callbackDeliveryMethods = this.callbackDeliveryMethods.bind(this);
    }

    componentDidMount() {
        CoreCart.getDeliveryMethods(this.callbackDeliveryMethods);
    }

    callbackDeliveryMethods(data) {
        this.setState({ options: data });
    }

    handleOptionChange = (changeEvent) => {

        if (changeEvent.target.value !== undefined) {
            this.setState({
                selectedOption: changeEvent.target.value
            });

            CoreCart.setDeliveryMethod(this.state.selectedOption);
        }
    }

    render() {
        let deliverMethods = "";

        if (this.state.options !== undefined && Array.isArray(this.state.options)) {
            deliverMethods = this.state.options.map((value, index) => (
                <div className="deliveryMethod" key={index} id={index}>

                    <label className="control-radio" >
                        <input type="radio" name="metod_selector" value={value.code} checked={(this.state.selectedOption !== undefined && this.state.selectedOption === value.code) || (this.state.selectedOption === undefined && value.default)} onChange={this.handleOptionChange} />
                        <div className="control_indicator"></div>
                    </label>

                    <div className="text"> {this.global.translate("delivery.method." + value.code + ".name")} </div>
                    <div className="pic_holding"> <img className="pic" src={"/images/delivery/" + value.code + ".png"} alt="delivery" /> </div>
                    <div className="price"> {this.global.toMoney(value.price)} </div>

                </div>

            ));
        }

        return (
            <div className="CartDeliveryCmp control-group">
                <div className="title"> {this.global.translate('delivery.method.name')} </div>
                {deliverMethods}
            </div>
        )
    }

}
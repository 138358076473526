import React, { PureComponent } from 'react'
import './EventEntries.scss';
//import PropTypes from 'prop-types'

export default class EventEntries extends PureComponent {
    static propTypes = {

    }

/**
 * function countDownToFutureDate(futureDate) {
  const timeDiff = Date.parse(futureDate) - Date.now();
  if (timeDiff <= 0) {
    return { days: 0, hours: 0, minutes: 0 };
  }
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  return { days, hours, minutes };
}


const futureDate = '2023-05-01T00:00:00Z';
const countdownEl = document.getElementById('countdown');

function updateCountdown() {
  const timeRemaining = countDownToFutureDate(futureDate);
  countdownEl.textContent = `Countdown: ${timeRemaining.days} days, ${timeRemaining.hours} hours, ${timeRemaining.minutes} minutes`;
}

updateCountdown();
setInterval(updateCountdown, 1000 * 60);
 * 
 */

    render() {

        const entries = [
            {
                "id": 1,
                "header": "Zdrowy Jaś",
                "date": "Appril 15, 2024",
                "image": "/images/extends/projekt_zdrowy_jas.jpg",
                "text": "Uczniowie 2 klasy z Ekonoma z Mińska Mazowieckiego realizowali w tym roku projekt w ramach ogólnopolskiej olimpiady “Zwolnieni z Teorii” pt. “Zdrowy Jaś”. Przeprowadzali oni zajęcia, podczas których poszerzali oni wiedze uczniów na temat zdrowego stylu życia. Beneficjentami tych zajęć były przedszkolaki i uczniowie szkół podstawowych. Projekt przeprowadził ponad 50 zajęć w których łącznie wzięło udział ponad 1300 dzieci!!!",
                "text2": "Serdeczne gratulacje dla wszystkich zaangażowanych w projekt wspierający zdrowy styl życia wśród młodzieży!",
                "text3": "Wasza praca ma nieocenione znaczenie dla edukacji młodych ludzi w zakresie zdrowego odżywiania, aktywności fizycznej i dobrych nawyków. Dzięki Wam młodzi ludzie uczą się, jak unikać negatywnych zachowań, radzić sobie ze stresem i cieszyć się zdrowym snem. Wasze zaangażowanie i poświęcenie przyczyniają się do budowania silniejszej, zdrowszej przyszłości dla nas wszystkich. Jesteście inspiracją dla nas i pokazujecie, jak wielką zmianę można wprowadzić poprzez pasję i ciężką pracę. Dziękujemy!"
            },
            
        ];


        return (

            <div className='EventEntries'>

                {entries.map((entry) => (
                    <div key={entry.id} className="blog-entry">
                        <div className="blog-content">
                            <div className="blog-header">{entry.header}</div>
                            <div className="blog-date">{entry.date}</div>
                            <div className="blog-text">{entry.text}</div>      
                            
                            <img src={ entry.image === undefined || entry.image === null ? "/images/core/default_blog.png" : entry.image } alt="" className={`blog-large-img`} />
                            <div className="blog-text">{entry.text2}</div>      
                            <div className="blog-text">{entry.text3}</div>      
                      
                        </div>
                    </div>
                ))}

            </div>

        )
    };
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CardPerson.scss';

export default class CardPerson extends Component {
    static propTypes = {
        img: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        essential1: PropTypes.string,
        essential2: PropTypes.string,
        essential3: PropTypes.string,
        linik: PropTypes.string
        //,backgroundColor: PropTypes.string.isRequired
    }


    render() {

        //var style = {backgroundColor: this.props.backgroundColor};//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);


        var $name = this.props.name === '' ? 'Albert Feynman' : this.props.name;

        var essence1 = this.props.essential1 !== undefined ? <div className="essence"><div className="icon"></div><div className="text">{this.props.essential1}</div></div> : null;
        var essence2 = this.props.essential2 !== undefined ? <div className="essence"><div className="icon"></div><div className="text">{this.props.essential2}</div></div> : null;
        var essence3 = this.props.essential3 !== undefined ? <div className="essence"><div className="icon"></div><div className="text">{this.props.essential3}</div></div> : null;


        return (
            <div className="CardPerson">
                <div className="card">

                    <div className="ds-top"></div>

                    <a target="_blank" href={this.props.link} rel="noopener noreferrer">
                        <div className="avatar-holder">
                            <img src={this.props.img} alt={$name} />
                        </div>
                        <div className="name">
                            {$name}
                        </div>
                    </a>

                    <div className="ds-skill">
                        <h6>Top Essentials <i className="fa fa-code" aria-hidden="true"></i></h6>

                        <div className="essence-holder">
                            {essence1}
                            {essence2}
                            {essence3}
                        </div>


                    </div>

                </div>
            </div>
        )
    }
}
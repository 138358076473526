import React, { Component } from 'reactn';
import './ButtonToTop.scss'

class ButtonToTop extends Component {
    constructor(props) {
        super(props)

        this.state = {

            "show": false
        }

        this.scrollFunction = this.scrollFunction.bind(this);
        this.topFunction = this.topFunction.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollFunction)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFunction)
    }


    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (window.location.hash) {
            window.location.hash = '';
        }

    }

    scrollFunction() {
        
        console.log(window.scrollY);
        if (window.scrollY > 300) {
            // Hide the menu
            this.setState({ "show": true });
          } else {
            // Show the menu
            this.setState({ "show": false });
          }

          /*
        
        if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
            this.setState({ "show": true });
        } else {
            this.setState({ "show": false });
        }*/
    }

    render() {

        let button = this.state.show ? <div onClick={this.topFunction} className="toTOP" title={this.global.translate('generic.toTop.description')}>{this.global.translate('generic.toTop')} <div className="arrow"> &uarr;</div></div> : '';

        return (
            <div className="ButtonToTop">
                {button}
            </div>
        )
    }
}

export default ButtonToTop;
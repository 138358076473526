import React, { Component } from 'reactn';
import './FilterList.scss';
import PropTypes from 'prop-types'
//import CoreRecipesFilters from '../../functional/core_recipes_filters';


export default class FilterList extends Component {
    static propTypes = {
        onUpdate: PropTypes.func.isRequired,
        filterNumber: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            filter: ''

        }

        this.handleChange = this.handleChange.bind(this);
        this.clearValue = this.clearValue.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    handleChange (e) {
        this.setState({[e.target.name]: e.target.value});
        this.props.onUpdate(e.target.value);
    }

    render() {
        let translate = this.global.translate;

        let filterTag = translate('filter');

        let clearTag = this.state.filter.length > 0 ? <div className='clear' onClick={this.clearValue}> <div className='text'>X</div></div> : '';
        

        return (
            <div className="FilterList">
                {clearTag}
                <input className='search-box' name="filter" type="search" placeholder={filterTag+'....'} autoFocus required value={this.state.filter} onChange={this.handleChange}/>           
                <div className='filterNumber' > <div className='text'>{this.props.filterNumber}</div></div>
            </div>
        );
    }

    onTodoChange(value){
        this.setState({
             name: value
        });
    }

    clearValue(){
        this.setState({
            filter: ''
       }); 
       this.props.onUpdate('');
    }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './GlowCircle.scss';

export default class GlowCircle extends Component {
    static propTypes = {
        img: PropTypes.string.isRequired
    }

    //</img> <img src={"/images/products/oils/" + this.props.img} alt={this.props.name} />

    render() {

        return (
            <div className="GlowCircle">

                <div className='holder'>
                    <div className='circle'></div>
                    <img className='img' src={"/images/products/oils/" + this.props.img} alt="oil of the month" />
              

                    <svg>

                        <filter id="wavy">
                            <feTurbulence x={0} y={0} baseFrequency={0.009} numOctaves="5" seed={55} >
                                <animate attributeName='baseFrequency' dur="60s" values='0.02;0.005;0.02' repeatCount={"indefinite"}></animate>


                            </feTurbulence>
                            <feDisplacementMap in="SourceGraphic" scale={60}></feDisplacementMap>
                        </filter>

                    </svg>
                </div>

            </div>
        )
    }
}

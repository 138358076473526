import React from 'reactn'
//import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import '../../GenericPage.scss';
import './OrdersPage.scss';
import CoreOrder from '../../../functional/core_orders';
import { CardHolderCmp } from "../../../elements"

export default class OrdersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderData: undefined,
            orderDataDetails: undefined
        }

        this.mapEntries = this.mapEntries.bind(this);
        this.mapOrders = this.mapOrders.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.callbackOrderList = this.callbackOrderList.bind(this);
        this.callbackOrderDetails = this.callbackOrderDetails.bind(this);
        this.callbackClearOrderDetails = this.callbackClearOrderDetails.bind(this);
    }


    componentDidMount() {
        CoreOrder.getOrderList(this.callbackOrderList);
    }

    handleImageLoaded(e, $pic) {
        e.target.src = $pic;
    }

    handleImageError(e) {
        e.target.src = "/images/core/camera_256x256.png";
    }

    callbackOrderList(data) {
        this.setState({ orderData: data });
    }

    loadOrderDetails(orderNo) {
        CoreOrder.getOrderDetails(orderNo, this.callbackOrderDetails);
    }

    callbackOrderDetails(data) {
        this.setState({ orderDataDetails: data })
    }

    callbackClearOrderDetails() {
        this.setState({ orderDataDetails: null });
    }

    mapOrders(entry, index) {

        return <div key={index} className="oderEntry" id={"oderEntry" + index} onClick={() => this.loadOrderDetails(entry.orderNo)}>

            <div id={"dateTag" + index} className="dateTag">{this.global.translate("orders.entry.date")}</div>
            <div id={"date" + index} className="date">{entry.date}</div>
            <div id={"realizationTag" + index} className="realizationTag">{this.global.translate("orders.entry.realization")}</div>
            <div id={"realization" + index} className="realization">{entry.realization}</div>
            <div id={"orderNoTag" + index} className="orderNoTag">{this.global.translate("orders.entry.orderNo")}</div>
            <div id={"orderNo" + index} className="orderNo">{entry.orderNo}</div>
            <div id={"valueTag" + index} className="valueTag">{this.global.translate("orders.entry.value")}</div>
            <div id={"value" + index} className="value">{this.global.toMoney(entry.value)}</div>
            <div id={"statusTag" + index} className="statusTag">{this.global.translate("orders.entry.status")}</div>
            <div id={"status" + index} className="status">{entry.status}</div>

        </div>
        //            <div className="showTag">Actions</div>
        //            <div className="show">{ButtonFun('Details', 'showBtn', 'show order details', null, false)}</div>

    }

    mapEntries(entry, index) {

        let translate = this.global.translate;

        return <div key={index} className="productEntry" id={"productEntry" + index}>
            <div className="header">
                <div className="hpic">{translate("cart.header.pic")}</div>
                <div className="hname">{translate("cart.header.name")}</div>
                <div className="basePrice">{translate("cart.header.basePrice")}</div>
                <div className="hactions">{translate("cart.header.actions")}</div>
                <div className="hprice">{translate("cart.header.price")}</div>
            </div>

            <Link to={'/pdp/' + entry.product.id} className="pic_holder" >
                <img className="pic" src="/images/core/camera_256x256.png" onLoad={(e) => (this.handleImageLoaded(e, entry.product.img))} onError={(e) => (this.handleImageLoaded(e))} alt="cart product" />
            </Link>

            <div className="name">
                {entry.product.displayName}
                <div className="category">
                    {entry.product.category}
                </div>
            </div>
            <div className="baseUnit">
                {entry.product.baseUnit}
            </div>

            <div className="unitPrice">
                {this.global.toMoney(entry.product.unitPrice)}
            </div>

            <div className="qty" title="ilosc">
                {entry.qty}
            </div>

            <div className="price">
                {this.global.toMoney(entry.qty * entry.product.unitPrice)}
            </div>

        </div>

    }

    render() {
        let translate = this.global.translate;

        let orderList = "";
        let isGoback = false;
        if (this.state.orderData !== undefined) {
            orderList = this.state.orderData.orderList.map(this.mapOrders);
        }

        let show = "";
        if (this.state.orderDataDetails !== undefined && this.state.orderDataDetails != null) {
            if (Array.isArray(this.state.orderDataDetails.entries)) {
                show = this.state.orderDataDetails.entries.map(this.mapEntries);
                isGoback = true;
            }
        } else {
            show = orderList
        }

        return (
            <div className="GenericPage OrdersPage">
                <CardHolderCmp title={translate("orders.header.title")} isGoBackVisiable={isGoback} customAction={this.callbackClearOrderDetails} >
                    {show}
                </CardHolderCmp>
            </div>
        )

    };

}
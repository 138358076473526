import React, { Component } from 'reactn'
import { Link } from "react-router-dom";
//import CoreApi from '../../../functional/core_api';
//import axios from 'axios';
import {
  HamburgerMenuCmp,
  DropdownMenuCmp,
  // MinicartCmp, 
  //    MyAccountMenuCmp, 
  //    SearchCmp 
} from '../../../elements';

import './NavBar.scss';

export default class NavBarCmp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navLinks: [],
      windowPOS: window.pageYOffset
    }


    this.mapNodes = this.mapNodes.bind(this);
  }


  componentDidMount() {
    /*axios.get(CoreApi.getNavMenuStructure)
        .then(res => {
            this.setState({ navLinks: res.data });
        }).catch(function (err) {
            console.log(err.message);
        });*/


    let json = [

      {
        "id": "string",
        "name": 'nav.links.team',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.team',
            "url": "/team/",
            "img": "string",
            "type": "_self"
          }
        ]
      },
      {
        "id": "string",
        "name": 'nav.links.oils',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.oils',
            "url": "/oils/",
            "img": "string",
            "type": "_self"
          }
        ]
      },
      {
        "id": "string",
        "name": 'nav.links.recipes',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.recipes',
            "url": "/recipes/",
            "img": "string",
            "type": "_self"
          }
        ]
      },
      {
        "id": "string",
        "name": 'nav.links.best_oil',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.best_oil',
            "url": "/best_oil/",
            "img": "string",
            "type": "_self"
          }
        ]
      },
      {
        "id": "string",
        "name": 'nav.links.blog',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.blog',
            "url": "/blog/",
            "img": "string",
            "type": "_self"
          }
        ]
      }
      ,
      {
        "id": "string",
        "name": 'nav.links.events',
        "elements": [
          {
            "id": "string",
            "name": 'nav.links.events',
            "url": "/events/",
            "img": "string",
            "type": "_self"
          }
        ]
      }



    ]

    this.setState({ navLinks: json });

    window.addEventListener('scroll', this.listenToScroll)
  }

  /**
   * {
                  "id": "string",
                  "name": 'nav.links.about',
                  "elements": [
                      {
                          "id": "string",
                          "name": 'nav.links.about.oils.info',
                          "url": "/oils_info/",
                          "img": "string",
                          "type": "_self"
                        },
                        {
                          "id": "string",
                          "name": 'nav.links.about.oils.usage',
                          "url": "/oils_usage/",
                          "img": "string",
                          "type": "_self"
                        }
                  ]
                }
   */

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  mapNodes(node, i) {
    if (node !== undefined && Array.isArray(node.elements)) {
      if (node.elements.length > 1) {
        return (<DropdownMenuCmp key={i} name={this.global.translate(node.name)} elements={node.elements} />);
      } else {

        let text = node.name;
        if (node.name === undefined) {
          text = node.elements[0].name;
        }
        return (<Link to={node.elements[0].url} className="nav_link" key={i} >{this.global.translate(text)}</Link>);
      }
    }
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    /*const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight*/

    const scrolled = winScroll; // add for scale of 1 => div by height

    this.setState({
      windowPOS: scrolled,
    })
  }

  isInViewport(offset = 0) {
    var element = document.getElementById('id_topnav')
    if (!element) return false;
    const top = element.getBoundingClientRect().top;
    return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
  }

  render() {
    var homeLink = (<Link to='/' className="home_link"><img src='/images/core/home_64x64.png' alt='' className='nav_link_icon' /></Link>);

    var dropdownMenuLink = "";
    var hamburgerMenuLink = "";
    if (Array.isArray(this.state.navLinks)) {
      dropdownMenuLink = <div className="full_menu"> {this.state.navLinks.map(this.mapNodes)} </div>;
      hamburgerMenuLink = <HamburgerMenuCmp elements={this.state.navLinks} />
    }

    var minicart = '';//<MinicartCmp />;

    var myAccount = ''; //<MyAccountMenuCmp />;

    var sticky = this.state.windowPOS > 48 ? ' sticky' : '';
       
    //this.state.windowPOS
    var search = '';//<SearchCmp />
    //console.log(this.state.windowPOS);

    return (
      <div>
        {sticky !== '' ? <div className="sticky_padding"></div> : null}
       
        <div className={"NavBarCmp " + sticky} id="id_topnav" >
          <div className="home">{homeLink}</div>
          <div className="navlinks">
            {dropdownMenuLink}
            {hamburgerMenuLink}
          </div>
          <div className="profile">{myAccount}</div>
          <div className="cart">{minicart}</div>
          <div className="search">{search}</div>
        </div>
      </div>
    )
  }

}

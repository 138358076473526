import React, { Component } from 'reactn'
import "./CartPaymnetCmp.scss";
import CoreCart from '../../../functional/core_cart';

export default class CartPaymnetCmp extends Component {

    // Set the state directly. Use props if necessary.
    state = {
        selectedOption: undefined,
        options: []
    }

    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.callbackPaymentMethods = this.callbackPaymentMethods.bind(this);
    }

    componentDidMount() {
        CoreCart.getPaymentMethods(this.callbackPaymentMethods);
    }

    callbackPaymentMethods(data) {
        this.setState({ options: data })
    }

    handleOptionChange = (changeEvent) => {

        let val = changeEvent.target.value;

        this.setState({
            selectedOption: val
        });

        CoreCart.setPaymentMethod(this.state.selectedOption);

    }

    render() {
        let deliverMethods = '';
        if (Array.isArray(this.state.options)) {
            deliverMethods = this.state.options.map((value, index) => (
                <div className="paymentMethod" key={index} id={index}>
                    <label className="control-radio" >
                        <input type="radio" name="payment_select" value={value.code} checked={this.state.selectedOption === value.code || (this.state.selectedOption === undefined && value.default)} onChange={(e) => this.handleOptionChange(e)} />
                        <div className="control_indicator"></div>
                    </label>

                    <div className="text"> {this.global.translate("payment.method." + value.code + ".name")}</div>
                    <div className="pic_holding"> <img className="pic" src={"/images/payments/" + value.code + ".png"} alt="payment" /></div>
                </div>

            ));
        }

        return (
            <div className="CartPaymnetCmp control-group">
                <div className="title"> {this.global.translate('payment.method.name')} </div>
                {deliverMethods}
            </div>
        )
    }

}

/*
 <label className="control control-radio">
                    Disabled
                    <input type="radio" name="radio2" disabled="disabled" />
                    <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                    Disabled & checked
                    <input type="radio" name="radio2" disabled="disabled" checked="checked" />
                    <div className="control_indicator"></div>
                </label>

*/
import React, { Component } from 'reactn'
import CoreApi from '../../../../functional/core_api';
import axios from 'axios';
import { Link } from "react-router-dom";
import "./SiteFooterCmp.scss";
import metadata from '../../../../../metadata.json'

export default class SiteFooterCmp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navLinks: []
        }
    }

    componentDidMount() {
        axios.get(CoreApi.getFooterNavLinks)
            .then(res => {
                this.setState({ navLinks: res.data });
            }).catch(function (err) {
                console.log(err.message);
            });
    }

    createLiNodes(elements) {

        if (Array.isArray(elements)) {

            return elements.map((node, j) => (
                <div className="line" key={j}>

                    {

                        this.isInternal(node.url) ?
                            (
                                <Link to={node.url} target={node.type}>
                                    {
                                        (node.img !== "undefined" && node.img !== '') ?
                                            (<img src={node.img} alt="pic" className=""></img>)
                                            :
                                            (null)
                                    }
                                    <p>{node.name}</p>

                                </Link>
                            ) : (
                                <a href={node.url} target={node.type}>
                                    {
                                        (node.img !== "undefined" && node.img !== '') ?
                                            (<img src={"/images/core/" + node.img} alt="pic" className=""></img>)
                                            :
                                            (null)
                                    }
                                    <p>{node.name}</p>

                                </a>
                            )

                    }

                </div>
            ))
        } else {
            return "";
        }
    }

    isInternal(toLocation) {
        return window.location.host === toLocation.host;
    }

    render() {
/*
        var columns = "";
        if (Array.isArray(this.state.navLinks)) {
            columns = this.state.navLinks.map((element, i) => (
                <div key={i} className="col-sm">
                    <h3>{element.name}</h3>
                    <div className="header">
                        {this.createLiNodes(element.elements)}
                    </div>
                </div>
            ))
        }

        
        return (
            <footer className="footer">
                <div className="row">
                    {columns}
                </div>
                <div className="copy_write">
                    {this.global.translate('generic.legalNote')}
                </div>
            </footer>
        )*/

        return (
            <footer className="footer">
              

                <div className="copy_write">
                    {this.global.translate('generic.legalNote')}
                    {` (Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}) `}
                
                </div>
            </footer>
        )
    }
}

import axios from 'axios';
import CoreApi from './core_api';

export default class CoreOrders {

    static getOrderList = (callbackFun) => {

        /*let config =
        {
            params: {
                code: productCode
            }
        }*/
        //, config
        axios.get(CoreApi.getOrderList).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not get product")
        )).catch(function (err) {
            console.log(err.message);
        });
    }


    static getOrderDetails = (orderNo, callbackFun) => {

        let config =
        {
            params: {
                orderNO: orderNo,
            }
        }

        axios.get(CoreApi.getOrderDetails, config).then(res => (
            res !== undefined && res.status === 200 ? callbackFun(res.data) : console.log("Could not get product")
        )).catch(function (err) {
            console.log(err.message);
        });
    }




}
import React, { Component } from 'reactn'
import PropTypes from 'prop-types'
import CoreFun from '../../../functional/core_fun';
import CoreCookies from '../../../functional/core_cookies';

import { SwitchDarkMode, ChangeLangCmp } from '../../../elements';
import "./HeadBannerCmp.scss";

class HeadBannerCmp extends Component {
    static propTypes = {
        url: PropTypes.string,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        logo: PropTypes.string
    }

    state = {
        whiteTheme: false
    }

    constructor(props) {
        super(props);

        var init_themeState = CoreCookies.getThemeState();

        this.state = {
            whiteTheme: Boolean(init_themeState)
        }

        console.log('white mode: ' + this.state.whiteTheme);
        this.changeTheme = this.changeTheme.bind(this);

    }

    componentDidMount() {
        CoreFun.setTheme(this.state.whiteTheme);
    }

    changeTheme() {

        let newState = !this.state.whiteTheme;
        this.setState({ whiteTheme: newState });
        console.log('changing white theme: ' + newState);
        CoreFun.setTheme(newState);
    }

    render() {

        var lang = <div className="lang_switch"><ChangeLangCmp /> </div>

        var themeSwitch = <div className="theme_switch"><SwitchDarkMode action={this.changeTheme} checked={this.state.whiteTheme} /> </div>

        return (
            <div className="HeadBannerCmp">

                <div id="id_header" className="header">

                    {lang}
                    {themeSwitch}

                    <a className="click_header" href={this.props.url} target="_self" rel="noopener noreferrer" >
                        <img src={this.props.logo} alt='' />
                    </a>
                    <div className="title">
                        <h1>{this.props.title}</h1>
                        <p>{this.props.subtitle}</p>
                    </div>

                </div>

            </div>
        )
    }


}


export default HeadBannerCmp;
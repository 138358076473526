import { getGlobal, setGlobal } from 'reactn';
import cms from "../assets/cms/cms_texts.json";

export const initCMS = () => {

    setGlobal({
        cms: getContent
    });

}


// Render all cards in the global state.
export const addContent = ($lang, $component, $element, $text) => {

    if (!cms[$lang]) {
        cms[$lang] = {}; // create a new language object if it doesn't exist
    }

    if (!cms[$lang][$component]) {
        cms[$lang][$component] = {}; // create a new component object if it doesn't exist
    }
    
    cms[$lang][$component][$element] = $text; // add the element and its text to the component object

};

// Render all cards in the global state.
export const getContent = ($component, $element, $page) => {

    let $lang = getGlobal().currentLang;

    let $pageLookUp = 'any';
    if($page !== null && $page !== undefined){
        $pageLookUp = $page;

    }

    if (!cms[$lang]) {
        console.log("TranslationManager missing translation language '" + $pageLookUp + ' ' + $component + ' ' + $element + "' lang: " + $lang);
        return "missing";
    }

    if (!cms[$lang][$pageLookUp]) {
        console.log("TranslationManager missing translation language '" + $pageLookUp + ' ' + $component + ' ' + $element + "' lang: " + $lang);
        return "missing";
    }

    if (!cms[$lang][$pageLookUp]["page_component_list"][$component]) {
        console.log("TranslationManager missing translation language '" + $pageLookUp + ' ' + $component + ' ' + $element + "' lang: " + $lang);      
        return "missing";
    }

    if (!cms[$lang][$pageLookUp]["page_component_list"][$component]["component_elements"][$element]) {
        console.log("TranslationManager missing translation language '" + $pageLookUp + ' ' + $component + ' ' + $element + "' lang: " + $lang);
        return "missing";
    } else {
        return cms[$lang][$pageLookUp]["page_component_list"][$component]["component_elements"][$element]["text"]
    }

};


import React, { Component } from 'reactn';
import './OilsCardHolder.scss';
import { CardOils, FilterList } from '../../elements';
import { CoreFun } from '../../functional';
//OilBottle
const alphabet = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export default class OilsCardHolder extends Component {
    constructor(props) {
        super(props);


        this.state = {
            filter: '',
            recipeList: [
                { name: 'adaptiv 10 ml', img: 'adaptiv-10ml-thumbnail-50x225-eu.png' },
                { name: 'adaptiv 15 ml', img: 'adaptiv-15ml-thumbnail-84x225-eu.png' },
                { name: 'air x 15 ml', img: 'air-x-15ml-thumbnail-84x225-eu.png' },
                { name: 'air 10 ml', img: 'air10ml-thumbnail-50x225-eu.png' },
                { name: 'air 15 ml', img: 'air15ml-thumbnail-84x225-eu.png' },
                { name: 'align 5 ml', img: 'align5ml-thumbnail-67x225-eu.png' },
                { name: 'anchor 5 ml', img: 'anchor5ml-thumbnail-67x225-eu.png' },
                { name: 'arborvitae 5 ml', img: 'arborvitae5ml-thumbnail-67x225-eu.png' },
                { name: 'arise 5 ml', img: 'arise5ml-thumbnail-67x225-eu.png' },
                { name: 'aromatouch', img: 'aromatouch-thumbnail-81x225-eu.png' },
                { name: 'balance', img: 'balance-thumbnail-81x225-eu.png' },
                { name: 'balance touch', img: 'balance-touch-thumbnail-51x225-eu.png' },
                { name: 'beautiful 10 ml', img: 'beautiful-10ml-thumbnail-50x225-eu.png' },
                { name: 'bergamot 15 ml', img: 'bergamot15ml-thumbnail-84x225-eu.png' },
                { name: 'bergamotmint', img: 'bergamotmint-thumbnail-86x225-eu.png' },
                { name: 'blackpepper 5 ml', img: 'blackpepper5ml-thumbnail-67x225-eu.png' },
                { name: 'blackspruce', img: 'blackspruce-thumbnail-67x225-eu.png' },
                { name: 'bluetansy 5 ml', img: 'bluetansy5ml-thumbnail-67x225-eu.png' },
                { name: 'cananga 15m', img: 'cananga-15ml.png' },
                { name: 'cardamom 5 ml', img: 'cardamom5ml-thumbnail-67x225-eu.png' },
                { name: 'cassia 15 ml', img: 'cassia15ml-thumbnail-84x225-eu.png' },
                { name: 'cedarwood 15 ml', img: 'cedarwood15ml-thumbnail-84x225-eu.png' },
                { name: 'celeryseed 15 ml', img: 'celeryseed-15ml-thumbnail-84x225-eu.png' },
                { name: 'cheer 10 ml', img: 'cheer10ml-thumbnail-50x225-eu.png' },
                { name: 'citronella 15 ml', img: 'citronella-15ml-thumbnail-84x225-eu.png' },
                { name: 'citrusbliss 15 ml', img: 'citrusbliss15ml-thumbnail-84x225-eu.png' },
                { name: 'citrustwist 15 ml', img: 'citrustwist-15ml-thumbnail-285x225-eu.png' },
                { name: 'clarysage 15 ml', img: 'clarysage15ml-thumbnail-84x225-eu.png' },
                { name: 'clementine 15 ml', img: 'clementine-15ml-thumbnail-84x225-eu.png' },
                { name: 'clove 15 ml', img: 'clove15ml-thumbnail-84x225-eu.png' },
                { name: 'console 5 ml', img: 'console5ml-thumbnail-67x225-eu.png' },
                { name: 'copaiba 15 ml', img: 'copaiba15ml-thumbnail-84x225-eu.png' },
                { name: 'coriander 15 ml', img: 'coriander15ml-thumbnail-84x225-eu.png' },
                { name: 'cypress 15 ml', img: 'cypress15ml-thumbnail-84x225-eu.png' },
                { name: 'davana', img: 'davana-thumbnail-51x225-eu.png' },
                { name: 'deepblue 5 ml', img: 'deepblue5ml-thumbnail-67x225-eu.png' },
                { name: 'eucalyptus 15 ml', img: 'eucalyptus15ml-thumbnail-84x225-eu.png' },
                { name: 'fennel 15 ml', img: 'fennel15ml-thumbnail-84x225-eu.png' },
                { name: 'forgive 10 ml', img: 'forgive10ml-thumbnail-50x225-eu.png' },
                { name: 'frankincense 15 ml', img: 'frankincense15ml-thumbnail-84x225-eu.png' },
                { name: 'ginger 15 ml', img: 'ginger15ml-thumbnail-84x225-eu.png' },
                { name: 'grapefruit 15 ml', img: 'grapefruit15ml-thumbnail-84x225-eu.png' },
                { name: 'greenmandarin 15 ml', img: 'greenmandarin15ml-thumbnail-84x225-eu.png' },
                { name: 'harvestspice  15 ml', img: 'harvestspice-15ml-thumbnail-84x225-eu.png' },
                { name: 'helichrysum touch', img: 'helichrysum-touch-thumbnail-51x225-eu.png' },
                { name: 'holidayjoy 15 ml', img: 'holidayjoy-15ml-thumbnail-285x225-eu.png' },
                { name: 'intune 10 ml', img: 'intune10ml-thumbnail-50x225-eu.png' },
                { name: 'jasmine 10 ml', img: 'jasmine10ml-thumbnail-50x225-eu.png' },
                { name: 'lavender 10 ml', img: 'lavender10ml-thumbnail-50x225-eu.png' },
                { name: 'lavender 15 ml', img: 'lavender15ml-thumbnail-84x225-eu.png' },
                { name: 'lemon 15 ml', img: 'lemon15ml-thumbnail-84x225-eu.png' },
                { name: 'lemon eucalyptus  15 ml', img: 'lemoneucalyptus-15ml-thumbnail-84x225-eu.png' },
                { name: 'lemongrass 15 ml', img: 'lemongrass15ml-thumbnail-84x225-eu.png' },
                { name: 'lime 15 ml', img: 'lime15ml-thumbnail-84x225-eu.png' },
                { name: 'magnolia 10 ml', img: 'magnolia10ml-thumbnail-50x225-eu.png' },
                { name: 'marjoram 15 ml', img: 'marjoram15ml-thumbnail-84x225-eu.png' },
                { name: 'melissa 5 ml', img: 'melissa5ml-thumbnail-67x225-eu.png' },
                { name: 'motivate 5 ml', img: 'motivate5ml-thumbnail-67x225-eu.png' },
                { name: 'myrrh 15 ml', img: 'myrrh15ml-thumbnail-84x225-eu.png' },
                { name: 'on guard beadlets', img: 'on-guard-beadlets-thumbnail-54x225px-eu.png' },
                { name: 'onguard 15 ml', img: 'onguard15ml-thumbnail-84x225-eu.png' },
                { name: 'passion 5 ml', img: 'passion5ml-thumbnail-67x225-eu.png' },
                { name: 'pasttense 10 ml', img: 'pasttense10ml-thumbnail-50x225-eu.png' },
                { name: 'patchouli 15 ml', img: 'patchouli15ml-thumbnail-84x225-eu.png' },
                { name: 'peppermint beadlets', img: 'peppermint-beadlets-thumbnail-54x225px-eu.png' },
                { name: 'peppermint 10 ml', img: 'peppermint10ml-thumbnail-50x225-eu.png' },
                { name: 'peppermint 15 ml', img: 'peppermint15ml-thumbnail-84x225-eu.png' },
                { name: 'petitgrain 15 ml', img: 'petitgrain15ml-thumbnail-84x225-eu.png' },
                { name: 'pink pepper 5 ml', img: 'pink-pepper-5ml-thumbnail-285x225-eu.png' },
                { name: 'purify 15 ml', img: 'purify15ml-thumbnail-84x225-eu.png' },
                { name: 'ravinstara', img: 'ravinstara-thumbnail-81x225-eu.png' },
                { name: 'redmandarin', img: 'redmandarin-thumbnail-85x225.png' },
                { name: 'romanchamomille 5 ml', img: 'romanchamomille5ml-thumbnail-67x225-eu.png' },
                { name: 'rose 10 ml', img: 'rose10ml-thumbnail-50x225-eu.png' },
                { name: 'rose 5 ml', img: 'rose5ml-thumbnail-67x225-eu.png' },
                { name: 'rosemary 15 ml', img: 'rosemary15ml-thumbnail-84x225-eu.png' },
                { name: 'serenity 15 ml', img: 'serenity15ml-thumbnail-84x225-eu.png' },
                { name: 'siberianfir 15 ml', img: 'siberianfir15ml-thumbnail-84x225-eu.png' },
                { name: 'tangerine 15 ml', img: 'tangerine15ml-thumbnail-84x225-eu.png' },
                { name: 'teatree 10 ml', img: 'teatree10ml-thumbnail-50x225-eu.png' },
                { name: 'teatree 15 ml', img: 'teatree15ml-thumbnail-84x225-eu.png' },
                { name: 'terrashield spray', img: 'terrashield-spray-thumbnail-48x225px-eu.png' },
                { name: 'terrashield 15 ml', img: 'terrashield15ml-thumbnail-84x225-eu.png' },
                { name: 'thyme 15 ml', img: 'thyme15ml-thumbnail-84x225-eu.png' },
                { name: 'tulsi', img: 'tulsi-thumbnail-85x225-eu.png' },
                { name: 'turmeric 15 ml', img: 'turmeric15ml-thumbnail-84x225-eu.png' },
                { name: 'whisper touch', img: 'whisper-touch-thumbnail-51x225-eu.png' },
                { name: 'wildorange 15 ml', img: 'wildorange15ml-thumbnail-84x225-eu.png' },
                { name: 'wintergreen 15 ml', img: 'wintergreen15ml-thumbnail-84x225-eu.png' },
                { name: 'white grapefruit 5 ml', img: 'white-grapefruit5ml-thumbnail.png' },

                { name: 'yarrow pom', img: 'yarrowpom-thumbnail-73x225-eu.png' },
                { name: 'yarrow pom serum 100 ml', img: 'yarrowpomserum-100ml-thumbnail-84x225-eu.png' },
                { name: 'yellow mandarin', img: 'yellowmandarin-thumbnail-85x225-eu.png' },
                { name: 'zendocrine 15 ml', img: 'zendocrine15ml-thumbnail-84x225-eu.png' },
                { name: 'zengest 10 ml', img: 'zengest10ml-thumbnail-50x225-eu.png' },
                { name: 'zengest 15 ml', img: 'zengest15ml-thumbnail-84x225-eu.png' },

            ],

            productsListFiltered: [
                { name: 'adaptiv 10 ml', img: 'adaptiv-10ml-thumbnail-50x225-eu.png' },
                { name: 'adaptiv 15 ml', img: 'adaptiv-15ml-thumbnail-84x225-eu.png' },
                { name: 'air x 15 ml', img: 'air-x-15ml-thumbnail-84x225-eu.png' },
                { name: 'air 10 ml', img: 'air10ml-thumbnail-50x225-eu.png' },
                { name: 'air 15 ml', img: 'air15ml-thumbnail-84x225-eu.png' },
                { name: 'align 5 ml', img: 'align5ml-thumbnail-67x225-eu.png' },
                { name: 'anchor 5 ml', img: 'anchor5ml-thumbnail-67x225-eu.png' },
                { name: 'arborvitae 5 ml', img: 'arborvitae5ml-thumbnail-67x225-eu.png' },
                { name: 'arise 5 ml', img: 'arise5ml-thumbnail-67x225-eu.png' },
                { name: 'aromatouch', img: 'aromatouch-thumbnail-81x225-eu.png' },
                { name: 'balance', img: 'balance-thumbnail-81x225-eu.png' },
                { name: 'balance touch', img: 'balance-touch-thumbnail-51x225-eu.png' },
                { name: 'beautiful 10 ml', img: 'beautiful-10ml-thumbnail-50x225-eu.png' },
                { name: 'bergamot 15 ml', img: 'bergamot15ml-thumbnail-84x225-eu.png' },
                { name: 'bergamotmint', img: 'bergamotmint-thumbnail-86x225-eu.png' },
                { name: 'blackpepper 5 ml', img: 'blackpepper5ml-thumbnail-67x225-eu.png' },
                { name: 'blackspruce', img: 'blackspruce-thumbnail-67x225-eu.png' },
                { name: 'bluetansy 5 ml', img: 'bluetansy5ml-thumbnail-67x225-eu.png' },
                { name: 'cananga 15m', img: 'cananga-15ml.png' },
                { name: 'cardamom 5 ml', img: 'cardamom5ml-thumbnail-67x225-eu.png' },
                { name: 'cassia 15 ml', img: 'cassia15ml-thumbnail-84x225-eu.png' },
                { name: 'cedarwood 15 ml', img: 'cedarwood15ml-thumbnail-84x225-eu.png' },
                { name: 'celeryseed 15 ml', img: 'celeryseed-15ml-thumbnail-84x225-eu.png' },
                { name: 'cheer 10 ml', img: 'cheer10ml-thumbnail-50x225-eu.png' },
                { name: 'citronella 15 ml', img: 'citronella-15ml-thumbnail-84x225-eu.png' },
                { name: 'citrusbliss 15 ml', img: 'citrusbliss15ml-thumbnail-84x225-eu.png' },
                { name: 'citrustwist 15 ml', img: 'citrustwist-15ml-thumbnail-285x225-eu.png' },
                { name: 'clarysage 15 ml', img: 'clarysage15ml-thumbnail-84x225-eu.png' },
                { name: 'clementine 15 ml', img: 'clementine-15ml-thumbnail-84x225-eu.png' },
                { name: 'clove 15 ml', img: 'clove15ml-thumbnail-84x225-eu.png' },
                { name: 'console 5 ml', img: 'console5ml-thumbnail-67x225-eu.png' },
                { name: 'copaiba 15 ml', img: 'copaiba15ml-thumbnail-84x225-eu.png' },
                { name: 'coriander 15 ml', img: 'coriander15ml-thumbnail-84x225-eu.png' },
                { name: 'cypress 15 ml', img: 'cypress15ml-thumbnail-84x225-eu.png' },
                { name: 'davana', img: 'davana-thumbnail-51x225-eu.png' },
                { name: 'deepblue 5 ml', img: 'deepblue5ml-thumbnail-67x225-eu.png' },
                { name: 'eucalyptus 15 ml', img: 'eucalyptus15ml-thumbnail-84x225-eu.png' },
                { name: 'fennel 15 ml', img: 'fennel15ml-thumbnail-84x225-eu.png' },
                { name: 'forgive 10 ml', img: 'forgive10ml-thumbnail-50x225-eu.png' },
                { name: 'frankincense 15 ml', img: 'frankincense15ml-thumbnail-84x225-eu.png' },
                { name: 'ginger 15 ml', img: 'ginger15ml-thumbnail-84x225-eu.png' },
                { name: 'grapefruit 15 ml', img: 'grapefruit15ml-thumbnail-84x225-eu.png' },
                { name: 'greenmandarin 15 ml', img: 'greenmandarin15ml-thumbnail-84x225-eu.png' },
                { name: 'harvestspice  15 ml', img: 'harvestspice-15ml-thumbnail-84x225-eu.png' },
                { name: 'helichrysum touch', img: 'helichrysum-touch-thumbnail-51x225-eu.png' },
                { name: 'holidayjoy 15 ml', img: 'holidayjoy-15ml-thumbnail-285x225-eu.png' },
                { name: 'intune 10 ml', img: 'intune10ml-thumbnail-50x225-eu.png' },
                { name: 'jasmine 10 ml', img: 'jasmine10ml-thumbnail-50x225-eu.png' },
                { name: 'lavender 10 ml', img: 'lavender10ml-thumbnail-50x225-eu.png' },
                { name: 'lavender 15 ml', img: 'lavender15ml-thumbnail-84x225-eu.png' },
                { name: 'lemon 15 ml', img: 'lemon15ml-thumbnail-84x225-eu.png' },
                { name: 'lemon eucalyptus  15 ml', img: 'lemoneucalyptus-15ml-thumbnail-84x225-eu.png' },
                { name: 'lemongrass 15 ml', img: 'lemongrass15ml-thumbnail-84x225-eu.png' },
                { name: 'lime 15 ml', img: 'lime15ml-thumbnail-84x225-eu.png' },
                { name: 'magnolia 10 ml', img: 'magnolia10ml-thumbnail-50x225-eu.png' },
                { name: 'marjoram 15 ml', img: 'marjoram15ml-thumbnail-84x225-eu.png' },
                { name: 'melissa 5 ml', img: 'melissa5ml-thumbnail-67x225-eu.png' },
                { name: 'motivate 5 ml', img: 'motivate5ml-thumbnail-67x225-eu.png' },
                { name: 'myrrh 15 ml', img: 'myrrh15ml-thumbnail-84x225-eu.png' },
                { name: 'on guard beadlets', img: 'on-guard-beadlets-thumbnail-54x225px-eu.png' },
                { name: 'onguard 15 ml', img: 'onguard15ml-thumbnail-84x225-eu.png' },
                { name: 'passion 5 ml', img: 'passion5ml-thumbnail-67x225-eu.png' },
                { name: 'pasttense 10 ml', img: 'pasttense10ml-thumbnail-50x225-eu.png' },
                { name: 'patchouli 15 ml', img: 'patchouli15ml-thumbnail-84x225-eu.png' },
                { name: 'peppermint beadlets', img: 'peppermint-beadlets-thumbnail-54x225px-eu.png' },
                { name: 'peppermint 10 ml', img: 'peppermint10ml-thumbnail-50x225-eu.png' },
                { name: 'peppermint 15 ml', img: 'peppermint15ml-thumbnail-84x225-eu.png' },
                { name: 'petitgrain 15 ml', img: 'petitgrain15ml-thumbnail-84x225-eu.png' },
                { name: 'pink pepper 5 ml', img: 'pink-pepper-5ml-thumbnail-285x225-eu.png' },
                { name: 'purify 15 ml', img: 'purify15ml-thumbnail-84x225-eu.png' },
                { name: 'ravinstara', img: 'ravinstara-thumbnail-81x225-eu.png' },
                { name: 'redmandarin', img: 'redmandarin-thumbnail-85x225.png' },
                { name: 'romanchamomille 5 ml', img: 'romanchamomille5ml-thumbnail-67x225-eu.png' },
                { name: 'rose 10 ml', img: 'rose10ml-thumbnail-50x225-eu.png' },
                { name: 'rose 5 ml', img: 'rose5ml-thumbnail-67x225-eu.png' },
                { name: 'rosemary 15 ml', img: 'rosemary15ml-thumbnail-84x225-eu.png' },
                { name: 'serenity 15 ml', img: 'serenity15ml-thumbnail-84x225-eu.png' },
                { name: 'siberianfir 15 ml', img: 'siberianfir15ml-thumbnail-84x225-eu.png' },
                { name: 'tangerine 15 ml', img: 'tangerine15ml-thumbnail-84x225-eu.png' },
                { name: 'teatree 10 ml', img: 'teatree10ml-thumbnail-50x225-eu.png' },
                { name: 'teatree 15 ml', img: 'teatree15ml-thumbnail-84x225-eu.png' },
                { name: 'terrashield spray', img: 'terrashield-spray-thumbnail-48x225px-eu.png' },
                { name: 'terrashield 15 ml', img: 'terrashield15ml-thumbnail-84x225-eu.png' },
                { name: 'thyme 15 ml', img: 'thyme15ml-thumbnail-84x225-eu.png' },
                { name: 'tulsi', img: 'tulsi-thumbnail-85x225-eu.png' },
                { name: 'turmeric 15 ml', img: 'turmeric15ml-thumbnail-84x225-eu.png' },
                { name: 'whisper touch', img: 'whisper-touch-thumbnail-51x225-eu.png' },
                { name: 'wildorange 15 ml', img: 'wildorange15ml-thumbnail-84x225-eu.png' },
                { name: 'wintergreen 15 ml', img: 'wintergreen15ml-thumbnail-84x225-eu.png' },
                { name: 'yarrow pom', img: 'yarrowpom-thumbnail-73x225-eu.png' },
                { name: 'yarrow pom serum 100 ml', img: 'yarrowpomserum-100ml-thumbnail-84x225-eu.png' },
                { name: 'yellow mandarin', img: 'yellowmandarin-thumbnail-85x225-eu.png' },
                { name: 'zendocrine 15 ml', img: 'zendocrine15ml-thumbnail-84x225-eu.png' },
                { name: 'zengest 10 ml', img: 'zengest10ml-thumbnail-50x225-eu.png' },
                { name: 'zengest 15 ml', img: 'zengest15ml-thumbnail-84x225-eu.png' },

            ],


        }

        this.mapRecipe = this.mapRecipe.bind(this);
        this.mapLetter = this.mapLetter.bind(this);
        this.mapLetterList = this.mapLetterList.bind(this);
        this.contains = this.contains.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }


    mapLetterList($letter, $index) {
        return <a href={'#' + $letter.letter} key={$letter.letter} className="link" >{$letter.letter}</a>
    }

    mapLetter($letter, $index) {
        return <div id={$letter.letter} key={$letter.letter} className="letter_holder" >

            <div className='letter'>{$letter.letter}</div>

            <div className='oilsHolder'>
                {$letter.list.map(this.mapRecipe)}
            </div>
        </div>
    }

    mapRecipe($recipe, $index) {
        return <CardOils key={$index} name={$recipe.name} img={$recipe.img} />
    }

    render() {

        var $recipeDOM;
        var $letterList = [];
        for (var letter of alphabet) {
            // eslint-disable-next-line no-loop-func
            $letterList.push({ letter: letter, list: this.state.productsListFiltered.filter((object) => this.startsWith(object, letter)) });
        }

        $letterList = $letterList.filter(CoreFun.isArrayWithEntries);
        $recipeDOM = $letterList.map(this.mapLetter);//this.state.recipeListFiltered.map(this.mapRecipe);

        var letterJumps = $letterList.map(this.mapLetterList);

        return (
            <div className="OilsCardHolder">
                <FilterList onUpdate={this.handleUpdate} filterNumber={this.state.productsListFiltered.length} />
                <div className='letter_list'>{letterJumps}</div>
                {$recipeDOM}
            </div>
        );
    }


    contains(object, filterText) {
        if (filterText.lenght === 0) {
            return true;
        }

        let $tmpfilter = filterText.toUpperCase();
        //console.log($tmpfilter);
        return object.name.toUpperCase().indexOf($tmpfilter) > -1
    }

    startsWith(object, filterText) {
        if (filterText.lenght === 0) {
            return true;
        }

        let $tmpfilter = filterText.toUpperCase();
        //sconsole.log($tmpfilter);
        return object.name.toUpperCase().startsWith($tmpfilter)
    }


    handleUpdate(filterText) {
        this.setState({ filter: filterText, recipeListFiltered: this.state.recipeList.filter((object) => this.contains(object, filterText)) });

    }

}


/*

                <OilBottle color='red' level='0.95' />
                <OilBottle color='blue' level='0.5' />
                <OilBottle color='green' level='0.6' />
                <OilBottle color='teal' level='0.7' />
                <OilBottle color='yellow' level='0.8' />
                 */
import React, { PureComponent } from 'react'
import './BlogEntries.scss';
//import PropTypes from 'prop-types'

export default class BlogEntry extends PureComponent {
    static propTypes = {

    }



    render() {

        const entries = [
            {
                "id": 1,
                "header": "The Healing Powers of Lavender Essential Oil",
                "date": "June 1, 2022",
                "image": null,
                "text": "Lavender essential oil has been used for centuries to promote relaxation and calmness. Its soothing aroma can help ease anxiety and stress, making it a popular choice for aromatherapy. In addition, lavender oil has antiseptic and anti-inflammatory properties, making it effective for treating minor skin irritations and wounds."
                },
            
                {
                "id": 2,
                "header": "The Benefits of Using Peppermint Oil",
                "date": "June 5, 2022",
                "image": null,
                "text": "Peppermint essential oil is known for its cooling and refreshing properties. It can help ease headaches, sinus congestion, and nausea. In addition, peppermint oil has antimicrobial properties, making it effective for treating a variety of skin conditions."
                },
            
                {
                "id": 3,
                "header": "Tea Tree Oil: A Natural Antiseptic",
                "date": "June 10, 2022",
                "image": null,
                "text": "Tea tree oil has powerful antimicrobial properties, making it effective for treating a variety of skin conditions such as acne, fungal infections, and dandruff. It can also help boost the immune system and reduce inflammation."
                },
            
                {
                "id": 4,
                "header": "The Soothing Properties of Chamomile Essential Oil",
                "date": "June 15, 2022",
                "image": null,
                "text": "Chamomile essential oil has a calming effect on the body and mind. It can help ease anxiety, promote relaxation, and improve sleep quality. In addition, chamomile oil has anti-inflammatory properties, making it effective for treating skin irritations and wounds."
                },
            
                {
                "id": 5,
                "header": "The Energizing Effects of Citrus Essential Oils",
                "date": "June 20, 2022",
                "image": null,
                "text": "Citrus essential oils such as lemon, grapefruit, and orange have a refreshing and energizing effect. They can help improve mood, reduce stress, and boost energy levels. In addition, citrus oils have antiseptic and astringent properties, making them effective for treating oily skin and acne."
                },
            
                {
                "id": 6,
                "header": "Frankincense Essential Oil: A Powerful Antioxidant",
                "date": "June 25, 2022",
                "image": null,
                "text": "Frankincense essential oil has been used for centuries for its medicinal properties. It has powerful antioxidant properties, making it effective for fighting inflammation and reducing the risk of chronic diseases. In addition, frankincense oil has a calming effect on the mind and can help reduce stress and anxiety."
                }
        ];


        return (

            <div className='BlogEntries'>

                {entries.map((entry) => (
                    <div key={entry.id} className="blog-entry">
                        <div className="blog-img-container">
                            <img src={ entry.image === undefined || entry.image === null ? "/images/core/default_blog.png" : entry.image } alt="" className={`blog-img`} />
                        </div>
                        <div className="blog-content">
                            <div className="blog-header">{entry.header}</div>
                            <div className="blog-date">{entry.date}</div>
                            <div className="blog-text">{entry.text}</div>
                        </div>
                    </div>
                ))}

            </div>

        )
    };
}

import React from 'reactn'
//import PropTypes from 'prop-types'
//import { Link } from "react-router-dom";
import '../GenericPage.scss';
import './CartPage.scss';
import CoreCart from '../../functional/core_cart';
import { RadialButtonFun } from '../../elements';
import { CartSummaryCmp } from './cart_summary';
import { CartDeliveryCmp } from './cart_delivery_method';
import { CartPaymnetCmp } from './cart_payment_method';
import { CartCoupons } from './cart_coupons';
import { Link } from "react-router-dom";


export default class CartPage extends React.Component {

    state = {}

    constructor(props) {
        super(props);
        this.mapEntries = this.mapEntries.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.removeEntry = this.removeEntry.bind(this);

        this.translate = this.global.translate;
        this.cart = this.global.cart;
        this.entries = this.global.entries;
        this.header = "";
    }

    componentDidMount() {
        CoreCart.getCart();
    }

    handleImageLoaded(e, $pic) {
        e.target.src = $pic;
    }

    handleImageError(e) {
        e.target.src = "/images/core/camera_256x256.png";
    }

    removeEntry(lineNo) {


        if (this.global.cart.entries !== undefined) {
            // <ModalCmp title="test"></ModalCmp>

            // ConfirmationCmp("hello","","");
            if (window.confirm(this.global.translate('cart.confirm.remove'))) {
                //console.log(line);
                var arr = this.global.cart.entries.reduce(function (c, n) {
                    if (n.lineNo === lineNo) { return c; }
                    c.push(n);
                    return c;
                }, []);

                let cart = this.global.cart;
                cart.entries = arr;
                this.setGlobal({ cart: cart });

                //console.log(this.global.cart.entries);
                CoreCart.removeProductFromCart(lineNo);
            }
        }
    }

    changeQty(lineNo, qty) {

        var arr = this.global.cart.entries.reduce(function (c, n) {
            if (n.lineNo === lineNo) {
                n.qty += qty;
            }
            c.push(n);
            return c;
        }, []);

        let cart = this.global.cart;
        cart.products = arr;
        this.setGlobal({ cart: cart });

        CoreCart.changeProductQuantity(lineNo, qty);
    }

    mapEntries(entry, index) {

        //let currLang = this.global.currentLang;

        return <div key={index} className="productEntry" id={"productEntry" + index}>
            {this.header}

            <Link to={'/pdp/' + entry.product.id} className="pic_holder" >
                <img className="pic" src="/images/core/camera_256x256.png" onLoad={(e) => (this.handleImageLoaded(e, entry.product.img))} onError={(e) => (this.handleImageLoaded(e))} alt="cart product" />
            </Link>

            <div className="name">
                {entry.product.displayName}
                <div className="category">
                    {entry.product.category}
                </div>
            </div>
            <div className="baseUnit">
                {entry.product.baseUnit}
            </div>

            <div className="unitPrice">
                {this.global.toMoney(entry.product.unitPrice)}
            </div>

            <div className="add">
                {RadialButtonFun('+', 'actionButtonAdd', this.translate('cart.qty.add'), () => { this.changeQty(entry.lineNo, +1) })}
            </div>

            <div className="qty" title="ilosc">
                {entry.qty}
            </div>

            <div className="subtracts">
                {RadialButtonFun('-', 'actionButtonsubtracts', this.translate('cart.qty.subtracts'), () => { this.changeQty(entry.lineNo, -1) })}
            </div>

            <div className="price">
                {this.global.toMoney(entry.qty * entry.product.unitPrice)}
            </div>

            <div className="remove">
                <div className="icon" onClick={() => (this.removeEntry(entry.lineNo))}></div>
            </div>

        </div>

    }


    render() {
        let cart = this.global.cart;

        var cartEntries = "";
        var cartSummary = "";
        var cartDeliveryMethods = "";
        var cartPaymnetMethods = "";
        var leadTime = "";
        var coupons = <CartCoupons />

        this.header = <div className="header">
            <div className="hpic">{this.translate("cart.header.pic")}</div>
            <div className="hname">{this.translate("cart.header.name")}</div>
            <div className="basePrice">{this.translate("cart.header.basePrice")}</div>
            <div className="hactions">{this.translate("cart.header.actions")}</div>
            <div className="hprice">{this.translate("cart.header.price")}</div>
        </div>

        if (cart !== undefined && cart.entries !== undefined && Array.isArray(cart.entries)) {
            let entries = cart.entries;
            cartEntries = entries.map(this.mapEntries)
            cartSummary = CartSummaryCmp(cart.cartValue, cart.cartDiscount, cart.cartVoucherValue, cart.cartDeliveryCost, cart.cartFreeShipment, cart.cartFreeShipmentValue);

            let days = this.global.cart.cartLeadTime <= 1 ? this.translate('cart.leadTime.workday') : this.translate('cart.leadTime.workdays')

            var future = new Date();
            future.setDate(future.getDate() + 1 + this.global.cart.cartLeadTime);

            let deliveryDay = future.getDay();
            
            while(!(deliveryDay !== 6 || deliveryDay !== 0)){
                deliveryDay += 1;
            }

            leadTime = <div className="leadTime">{this.translate('cart.leadTime.description') + ' ' + this.global.cart.cartLeadTime + ' ' + days + ' (' + this.translate('generic.weekday.'+deliveryDay+'') + ')'}</div>

        }

        cartDeliveryMethods = <CartDeliveryCmp />
        cartPaymnetMethods = <CartPaymnetCmp />

        return (
            <div className="GenericPage CartPage">

                <div className="productEntries">
                    <div className="title">{this.translate("cart.header.title")}</div>
                    {cartEntries}
                    {leadTime}
                </div>
                <div className="deliveryPaymnetHolder">
                    <div className="delivery">{cartDeliveryMethods}</div>
                    <div className="payment">{cartPaymnetMethods}</div>
                </div>
                <div className="cartCoupons">
                    <div className="cupons">{coupons}</div>
                    <div className="summary">{cartSummary}</div>
                </div>
            </div>
        )

    };


}
import React, { Component } from 'reactn'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import "./DropdownMenuCmp.scss";

export default class DropdownMenuCmp extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        elements: PropTypes.array.isRequired,
        icon: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    toggleMenu(event) {
        if (!this.state.showMenu) {
            //document.dispatchEvent(new Event("click"));
            this.setState({ showMenu: true });
            document.addEventListener('click', this.closeMenu);
            event.stopPropagation();
         
        } else {
            this.setState({ showMenu: false });
            document.removeEventListener('click',  this.closeMenu);
            
            if (event.target.nodeName !== 'A' && event.currentTarget.className === "dropdown_menu"){
                event.stopPropagation();
            }
        }
    }

    closeMenu(event) {
        if (this.state.showMenu) {
            this.setState({ showMenu: false });
                       
            document.removeEventListener('click',  this.closeMenu);
        }
    }

    componentWillUnmount() {
        this.closeMenu(); 
    }

    render() {

        var menu_elements = "";
        if (this.props.elements !== undefined && Array.isArray(this.props.elements)) {
            menu_elements = this.props.elements.map((entry, i) => (<Link key={i} to={entry.url}>{this.global.translate(entry.name)}</Link>))
        }
        var dropdwonclasses = this.state.showMenu ? "selected" : '';

        let changeChar = " drop_menu_close";

        if (this.state.showMenu) {
            changeChar = " drop_menu_open";
        }

        let icon = "";

        if (this.props.icon !== undefined) {
            icon = <img src={"/images/core/" + this.props.icon} alt="drop down" className="dropdown_icon" id="dropdown_icon"></img>
        }

        return (
            <div className={"dropdown_menu"} onClick={this.toggleMenu} >

                <div className={"pline " + dropdwonclasses + changeChar}>
                    <div className="icon"> {icon} </div>
                    <div className="text"> {this.props.name}  </div>
                </div>

                {
                    this.state.showMenu && menu_elements.length > 0
                        ? (
                            <div className="dropdown_content" >
                                <div className="dropdown_elements">
                                    {menu_elements}
                                </div>
                            </div>
                        ) : (
                            null
                        )
                }

            </div>
        )
    }
}


//{menu_elements.length > 0 ? (<img src='/images/core/drop_down_64x64.png' alt='>' />) : (null)}

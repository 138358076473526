//import { setGlobal } from 'reactn'
import axios from 'axios';
import CoreApi from './core_api';
const qs = require('qs');

export default class CoreDelivery {

    static getAddresses(returnVariable) {
        axios.get(CoreApi.getDeliveryAddresses).then(res => (
            res !== undefined && res.status === 200 ? returnVariable = res.data : console.log("did not retrive any data")
        )).catch(function (err) {
            console.log(err.message);
        });
    }

    static removeAddresses(addressID) {

        console.log(addressID);
        axios.post(CoreApi.removeDeliveryAddress, qs.stringify({ "id": addressID })).then(res => (
            res !== undefined && res.status === 200 ? console.log("address removed") : console.log("address not removed")
        )).catch(function (err) {
            console.log(err.message);
        });
    }

    static getDeliveryAddresses(callback) {
        axios.get(CoreApi.getDeliveryMethod)
            .then(res => {
                res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrive any data")
            }).catch(function (err) {
                console.log(err.message);
            })
    }


}
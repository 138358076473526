import React, { Component } from 'reactn'
import PropTypes from 'prop-types'
import './AnimatedDrop.scss';
import { BubbleText } from '../'

export default class AnimatedDrop extends Component {
    static propTypes = {
        img: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
        //,backgroundColor: PropTypes.string.isRequired
    }


    render() {

        //var style = {backgroundColor: this.props.backgroundColor};//(backgroundColor !== undefined ? {color: this.props.backgroundColor} : null);


        let greetings = this.global.translate("generic.greetings");

        return (
            <div className="AnimatedDrop">
                <div className="holder">
                    <BubbleText text={greetings }></BubbleText>
                    <img rel="preload" src={this.props.img} alt={this.props.alt} className="drop-img" />
                </div>
            </div>
        )
    }
}

import Cookies from 'universal-cookie';
import CoreApi from './core_api';
import axios from 'axios';

const cookies = new Cookies();

export default class CoreCookies {

    static default_options = { path: '/', maxAge: 60 * 60 * 24 * 7 * 365, sameSite: 'Lax', secure: false };

    //GETS
    static cookie_white_theme = 'white_theme';
    static cookie_disclaimer = 'cookie_disclaimer';
    static cookie_lang = 'cookie_lang';

    static convertBoolean(value) {

        let retVal = false;
        //value === undefined ? retVal = false : retVal = true;

        if (value === undefined) {
            return false;
        }

        if (value === 'false' || value === false) {
            retVal = false;
        }

        if (value === 'true' || value === true) {
            retVal = true;
        }

        return retVal;
    }

    static convertBooleanDefault(value, defaults) {

        let retVal = false;
        //value === undefined ? retVal = false : retVal = true;

        if (value === undefined) {
            retVal = defaults;
        }

        if (value === 'false' || value === false) {
            retVal = false;
        }

        if (value === 'true' || value === true) {
            retVal = true;
        }

        return retVal;
    }

    static convertBooleanDirect(value, defaults) {

        let retVal = value;


        if (value === 'false' || value === false) {
            retVal = false;
        }

        if (value === 'true' || value === true) {
            retVal = true;
        }

        return retVal;
    }


    //THEME
    static getThemeState() {
        var value = cookies.get(CoreCookies.cookie_white_theme);

        let $ret = CoreCookies.convertBooleanDirect(value);
        

        if($ret === undefined){
            $ret = true;
            CoreCookies.setThemeState($ret);
        }


        return $ret;
    }

    static setThemeState(status) {
        cookies.set(CoreCookies.cookie_white_theme, status, CoreCookies.default_options);  // 1*365 week 
    }

    //DISCLAIMER
    static getDisclaimerStatus() {
        let value = cookies.get(CoreCookies.cookie_disclaimer);
        return CoreCookies.convertBoolean(value);
    }

    static setDisclaimer(show) {
        cookies.set(CoreCookies.cookie_disclaimer, show, CoreCookies.default_options);  // 1*365 week 
    }
    
    //LANGUAGE
    static getLanguage() {
        let value = cookies.get(CoreCookies.cookie_lang);
        return value;
    }

    static setLanguage(lang) {
        cookies.set(CoreCookies.cookie_lang, lang, CoreCookies.default_options);  // 1*365 week 
    }

    //SET SESSION
    static setSessionID(sessionID) {
        cookies.set(CoreApi.sessionID, sessionID, CoreCookies.default_options);  // 1*365 week     
        axios.defaults.headers.common[CoreApi.sessionID] = sessionID // for all requests
    }

    //RELEASE SESSION
    static releaseSession() {
        cookies.remove(CoreApi.sessionID);
        axios.defaults.headers.common[CoreApi.sessionID] = '' // for all requests
    }

    //GET SESSION
    static getSessionID(){
        return cookies.get(CoreApi.sessionID);
    }


}
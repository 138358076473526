import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CardOils.scss';

export default class CardOils extends Component {
    static propTypes = {
        img: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
        //,backgroundColor: PropTypes.string.isRequired
    }
    // <h4>Idealne do relasacji</h4>
    // <p>Olejek z drzewa herbacianego to ekstrakt z liści drzewa herbacianego (Melaleuca alternifolia) z rodziny mirtowatych, które naturalnie występuje w Australii. Skład olejku stanowią w większości związki fenolowe i terpeny, które wykazują działanie przeciwdrobnoustrojowe. Olejek herbaciany zawiera także monoterpeny, w tym terpinen, cyneol, cymen, diterpeny, seskwiterpeny i wiele innych substancji. Ma on postać rzadkiego oleju o jasnożółtej barwie oraz intensywnym, kamforowym zapachu. Mieszkańcy Australii już od setek lat korzystali z właściwości tej rośliny – używali jej przede wszystkim jako preparat leczniczy.</p>

    render() {

        return (
            <div className="CardOils">


                <div className="card">
                    <nav>
                        <h2>{this.props.name}</h2>
                    </nav>
                    <div className="photo">
                        <img src={"/images/products/oils/" + this.props.img} alt={this.props.name} />

                    </div>
                  
                </div>
            </div>
        )
    }
}

import React, { Component } from 'reactn'
import { ThreeImagesCmp, AnimatedDrop, PageContentImageCmp } from '../../elements/';
import '../GenericPage.scss';
import './HomePage.scss';


export default class HomePage extends Component {

    render() {

        let $txt1 = this.global.cms("ThreeImagesCmp", "text-l");
        let $txt2 = this.global.cms("ThreeImagesCmp", "text-c");
        let $txt3 = this.global.cms("ThreeImagesCmp", "text-r");

        return (
            <div className="GenericPage HomePage">

                <PageContentImageCmp img="/images/banners/banner_homepage.jpg" alt="promo" backgroundColor="#101010" />

                <ThreeImagesCmp
                    imgs={["/images/banners/banner_contact_300x200.jpg", "/images/banners/banner_sunflower_300x200.jpg", "/images/banners/banner_consider_300x200.jpg"]}
                    links={["https://www.facebook.com/OlejkoweNuty/", "https://www.facebook.com/OlejkoweNuty/", "/oils/"]}
                    texts={[$txt1, $txt2, $txt3]}
                    backgroundColor=""
                    fontColorClass=""
                />

                <AnimatedDrop img="favicon_olejkowenuty-v2.webp" alt="drop" />



            </div>
        )
    }
}

//<BeatingHeart img="/images/core/homepage.webp" alt="heart" />

/* fontColorClass="black_font" */
/*
              <LogoShowCaseCmp />

  TwoImagesCmp,
                <TwoImagesCmp
                    imgs={["/images/banners/banner_piano.jpg", "/images/banners/banner_notes.jpg"]}
                    links={["/milestones", "about"]}
                    texts={["", ""]}
                    backgroundColor=""
                    fontColorClass="black_font"
                />
 
{
  ButtonGlass('read more content', 'actionButtonAdd', this.global.translate('cart.qty.add'), null)
}*/

import React, { Component } from 'reactn'
import PropTypes from 'prop-types'
import { DropdownMenuCmp } from '../';
import { Link } from "react-router-dom";
import "./HamburgerMenuCmp.scss";

export default class HamburgerMenuCmp extends Component {
    static propTypes = {
        elements: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.mapNodes = this.mapNodes.bind(this);
    }

    toggleMenu(event) {
        event.preventDefault();

        let show = !this.state.showMenu;

        document.getElementsByClassName("hamburger_icon")[0].classList.toggle("change");
        
        if (show) {
            this.setState({ showMenu: true });
            document.addEventListener('click', this.closeMenu);
            
        
        } else {
            this.setState({ showMenu: false });
            document.removeEventListener('click', this.closeMenu);

        }
    }

    closeMenu(event) {

        let show = this.state.showMenu;
        if (show) {

            if (this.dropdownMenu !== undefined && event.target.tagName !== undefined) {
                if ( !this.dropdownMenu.contains(event.target) && event.target.tagName.toLowerCase() === "a") {

                    this.setState({ showMenu: false });
                    document.removeEventListener('click', this.closeMenu);

                }

            }
        }


        /*
        if (
            this.dropdownMenu !== undefined &&
            (
                !this.dropdownMenu.contains(event.target) ||
                event.target.tagName.toLowerCase() === "a"
            )
        ) {

            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }*/

    }

    mapNodes(node, i) {
        if (node !== undefined && Array.isArray(node.elements)) {
            if (node.elements.length > 1) {
                return (<DropdownMenuCmp key={i} name={this.global.translate(node.name)} elements={node.elements} />);
            } else {

                let text = node.name;
                if (node.name === undefined) {
                    text = node.elements[0].name;
                }
                return (<Link to={node.elements[0].url} className="nav_link" key={i}>{this.global.translate(text)}</Link>);
            }
        }
    };


    //onClick="menuRWD()"
    render() {

        //  var menu_elements = this.state.elements.map( (entry, i) => (<Link key={i} to={entry.url}>{entry.name}</Link>) )

        let show = this.state.showMenu;
        //console.log(this.state.showMenu);
        if (show) {
            var dropdownMenuLink = this.props.elements.map(this.mapNodes);
        }

        return (
            <div className="HamburgerMenuCmp" onClick={this.toggleMenu}>
                <div className="hamburger_icon">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>


                {
                    show && dropdownMenuLink.length > 0
                        ? (
                            <div className="hamburger_content" ref={(element) => { this.dropdownMenu = element; }}>
                                <div className="dropdown_content">
                                    <div className="dropdown_elements">
                                        {dropdownMenuLink}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                }

            </div>
        )
    }
}

import React, { Component } from 'reactn'

import "./ChangeLangCmp.scss";

export default class ChangeLangCmp extends Component {

    render() {
        let src = "/images/core/langs/";
        let lang = this.global.currentLang;
        if (lang === 'pl' || lang === undefined) {
            src += 'pl.png'
        }

        if (lang === 'en') {
            src += 'en.png'
        }

        return (
            <div>
                <img onMouseDown={this.global.changeLang} className={"ChangeLangCmp"} src={src} alt={"change language to: " + this.global.alternateLang} ></img>
            </div>
        )
    }

}

/*


const ChangeLangCmp = () => {

    let src = "/images/core/langs/";
    var global = getGlobal()
    var lang = global.currentLang;

    return (
        <img onMouseDown={global.changeLang} className={"ChangeLangCmp"} src={src} alt={"change language to: " + global.alterLang} ></img>
    )

}

export default ChangeLangCmp;

import React, { Component } from 'react'


*/
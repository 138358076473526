import CoreCookies from '../functional/core_cookies';

export default class CoreFun {

    static setTheme(whiteMode) {
        var element = document.getElementsByTagName("body")[0];

        if (whiteMode) {
            element.setAttribute("data-theme", "white");//default
            element.className = "white_fury";
        } else {
            element.setAttribute("data-theme", "dark_fusion");
            element.className = "dark_fusion";
        }

        CoreCookies.setThemeState(whiteMode);
    }

    isInternal(toLocation) {
        return window.location.host === toLocation.host;
    }

    static stringToBoolean(s) {
        switch (String(s).toLowerCase()) {
            case "true":
            case "1":
            case "prawda":
            case "on":
            case "yes":
            case "y":
            case "tak":
            case "x":
                return true;

            case "false":
            case "0":
            case "fałsz":
            case "off":
            case "no":
            case "n":
            case "nie":
            case "":
                return false;

            default: return false;
        }
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    static initArrayUniqueMethod() {
        // eslint-disable-next-line
        Array.prototype.unique = function () {
            return this.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
        }
    }

    static isArrayWithEntries(arrayWithEntries) {
        if (arrayWithEntries === null) {
            return false;
        }

        if (Array.isArray(arrayWithEntries.list)) {
            return arrayWithEntries.list.length > 0;
        }

        return false;

    }

}
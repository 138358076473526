
export default class CoreApi {

    static baseUrlMock = "http://127.0.0.1:3100";
    static baseUrl = "https://altera.vm/api";
    static sessionID = 'SID';    

    //SESSION GET
    static getSessionStatus = `${CoreApi.baseUrl}/session/getSessionStatus`;
    static getSearchResults = `${CoreApi.baseUrl}/search/getSearchResults`;
    static Logout = `${CoreApi.baseUrl}/session/logout`;

    //PRODUCT GET 
    static getProductData = `${CoreApi.baseUrl}/product/getProductData`; //{code} 
    static getGaleryImages = `${CoreApi.baseUrl}/product/getGaleryImages`; //{code}
    static getProductListing = `${CoreApi.baseUrl}/product/getProductListing`; //{listingType} {filters}
    
    //GIFTCARD GET
    static getGiftCards = `${CoreApi.baseUrl}/giftcard/getGiftCards`;
    
    //CART GET 
    static getCart = `${CoreApi.baseUrl}/cart/getCart`;
    static getMinicartInformation = `${CoreApi.baseUrl}/cart/getMiniCart`;
    static getPromotionList = `${CoreApi.baseUrl}/cart/getPromotionList`;
    static getDeliveryMethod = `${CoreApi.baseUrl}/cart/getDeliveryMethod`;
    static getPaymentMethod = `${CoreApi.baseUrl}/cart/getPaymentMethod`;
    static getAciveCoupons = `${CoreApi.baseUrl}/cart/getAciveCoupons`;
    static setCartDeliveryMethod = `${CoreApi.baseUrl}/cart/setCartDeliveryMethod`;
    
    //CART POST
    static addToCart = `${CoreApi.baseUrl}/cart/addToCart`;
    static useCoupon = `${CoreApi.baseUrl}/cart/useCoupon`;
    static removeCoupon = `${CoreApi.baseUrl}/cart/removeCoupon`;
    static setDeliveryMethod = `${CoreApi.baseUrl}/cart/setDeliveryMethod`;
    static setPaymentMethod = `${CoreApi.baseUrl}/cart/setPaymentMethod`;
    static addActiveCoupon = `${CoreApi.baseUrl}/cart/addActiveCoupon`;
    static changeProductQuantity = `${CoreApi.baseUrl}/cart/changeProductQuantity`;
    static removeProductFromCart = `${CoreApi.baseUrl}/cart/removeProductFromCart`;
 
    //CMS GET
    static getFooterNavLinks = `${CoreApi.baseUrl}/cms/getFooterNavLinks`;
    static getMyAccountNavLinks = `${CoreApi.baseUrl}/cms/getMyAccountNavLinks`;
    static getPromotionsList = `${CoreApi.baseUrl}/cms/getPromotionsList`;
    static getNavMenuStructure = `${CoreApi.baseUrl}/cms/getNavLinks`;

    //DELIVERY GET
    static getDeliveryMethods = `${CoreApi.baseUrl}/delivery/getDeliveryMethods`;
    static getCartDeliveryCost = `${CoreApi.baseUrl}/delivery/getCartDeliveryCost`;
    static getDeliveryAddresses = `${CoreApi.baseUrl}/delivery/getDeliveryAddresses`;

    //PAYMENT GET
    static getPaymentMethods = `${CoreApi.baseUrl}/payment/getPaymentMethods`;

    //STORES GET
    static getStoreList = `${CoreApi.baseUrl}/store/getStoreList`;

    //ORDERS GET
    static getOrderList = `${CoreApi.baseUrl}/orders/getOrderList`;
    static getOrderDetails = `${CoreApi.baseUrl}/orders/getOrderDetails`;

    //CUSTOMER GET
    static getProfileData = `${CoreApi.baseUrl}/customer/getProfileData`;

    //CUSTOMER POST
    static addRateUs  = `${CoreApi.baseUrl}/customer/addRateUs`;

    //INSTEGRAM GET
    static getInstagramMedia  = `https://graph.instagram.com/me/media`;

    //Facebook GET
    static getFacebookMedia = 'https://graph.facebook.com/v14.0/{page-id}/feed'
}